/* --------------------------------
 * header
 * -------------------------------- */
header {
	position: relative;
	height: 100px;
	max-width: 1000px;
	margin: 0 auto 10px;
	padding-left: 15px;
	padding-right: 15px;
}
header a {
	text-decoration: none;
}
header .header-inner {
	position: relative;
	height: 75px;
	background-color: #ffffff;
}
header .logo {
	position: absolute;
	width: 260px;
	top: 10px;
	left: 0;
}
header .logo img {
	width: 100%;
}

@media screen and (max-width: 767px) {
header {
	position: fixed;
	width: 100%;
	height: 60px;
	padding-left: 0;
	padding-right: 0;
	z-index: 9999;
}
header .header-inner {
	height: 60px;
}
header .header-inner:after {
	position: absolute;
	content: '';
	width: 100%;
	height: 1px;
	top: 60px;
	// background-color: #9fa0a0;
}
header .logo {
	width: 180px;
	top: 13px;
	left: 15px;
}
}

@media screen and (max-width: 374px) {
header .logo {
	width: 160px;
	top: 13px;
	left: 15px;
}
}
header .mobile-btn {
	display: none;
}

@media screen and (max-width: 767px) {
header .mobile-btn {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
}
header .mobile-btn li {
	float: left;
	border-left: 1px solid #9fa0a0;
}
header .mobile-btn button {
	display: block;
	width: 50px;
	height: 60px;
	padding: 5px 0 0;
	border: none;
	background-color: #ffffff;
	cursor: pointer;
	outline: none;
}
header .mobile-btn button span {
	display: inline-block;
	width: 40px;
	height: 50px;
	overflow: hidden;
	text-indent: -9999px;
	// background-image: url(/common/img/icon_mob_navi.png);
	background-image: url(/assets/img/icon_mob_menu2.png);
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: 80px 250px;
}
header .mobile-btn button.btn-menu span {
	background-position: 0 0;
}
header .mobile-btn button.btn-search span {
	background-position: 0 -50px;
}
:lang(ja) header .mobile-btn button.btn-lang span {
	background-position: 0 -100px;
}
:lang(en) header .mobile-btn button.btn-lang span {
	background-position: 0 -150px;
}
:lang(zh) header .mobile-btn button.btn-lang span {
	background-position: 0 -200px;
}
header .mobile-btn button.active {
	color: #ffffff;
	background-color: #595757;
}
header .mobile-btn button.active.btn-menu span {
	background-position: -40px 0;
}
header .mobile-btn button.active.btn-search span {
	background-position: -40px -50px;
}
:lang(ja) header .mobile-btn button.active.btn-lang span {
	background-position: -40px -100px;
}
:lang(en) header .mobile-btn button.active.btn-lang span {
	background-position: -40px -150px;
}
:lang(zh) header .mobile-btn button.active.btn-lang span {
	background-position: -40px -200px;
}
}

@media screen and (max-width: 374px) {
header .mobile-btn button {
	width: 44px;
}
}
.nav-util {
	position: absolute;
	top: 25px;
	right: 15px;
}
.nav-util:after {
	content: '';
	display: block;
	clear: both;
}
.util-inquiry, .util-lang, .util-search {
	float: left;
}
.util-inquiry, .util-lang {
	margin-right: 10px;
}

// .util-inquiry {
// 	display: none;
// }


:lang(ja) .util-inquiry {
	display: none;
}

@media screen and (max-width: 767px) {
#nav, .util-lang, .util-search {
	visibility: hidden;
	opacity: 0;
	/*transition: all 300ms;*/
	float: none;
	position: fixed;
	top: 60px;
	left: 0;
	width: 100%;
	height: calc( 100% - 60px );
	margin-right: 0;
	overflow: hidden;
	background-color: rgba(0,0,0,0.3);
	z-index: 9999;
}
#nav.open, .util-lang.open, .util-search.open {
	visibility: visible;
	opacity: 1;
	transition: all 300ms;
}
.gl-item {
	padding: 15px 10px;
	background-color: #595757;
}
}
.util-inquiry {
}
.util-inquiry a {
	font-size: 1.2rem;
	display: block;
	padding: 3px 0;
}
.util-inquiry a::before {
	font-family: 'fontello';
	content: '\e800';
	margin-right: 3px;
}

@media screen and (max-width: 767px) {
.util-inquiry {
	display: none;
}
}
.util-lang li:nth-child(1) a:before {
	content: 'JP';
}
.util-lang li:nth-child(2) a:before {
	content: 'EN';
}
.util-lang li:nth-child(3) a:before {
	content: 'CN';
}
:lang(ja) .util-lang li:nth-child(1) a, :lang(en) .util-lang li:nth-child(2) a, :lang(zh) .util-lang li:nth-child(3) a {
	color: #ffffff;
	background-color: #727171;
	pointer-events: none;
}
.util-lang ul:after {
	content: '';
	display: block;
	clear: both;
}
.util-lang li {
	float: left;
	margin-right: 1px;
}
.util-lang li a {
	font-size: 1.2rem;
	text-align: center;
	display: block;
	padding: 3px 25px;
	background-color: #cccccc;
}
.util-lang li a:hover {
	color: #ffffff;
	background-color: #727171;
}

@media screen and (max-width: 900px) {
.util-lang li a {
	padding: 3px 15px;
}
}

@media screen and (max-width: 767px) {
.util-lang li:nth-child(1) a:before {
	content: '日本語';
}
.util-lang li:nth-child(2) a:before {
	content: 'English';
}
.util-lang li:nth-child(3) a:before {
	content: '簡体字';
}
.util-lang.util-lang-en li:nth-child(1) a:before {
	content: '日本語';
}
.util-lang.util-lang-en li:nth-child(2) a:before {
	content: 'English';
}
.util-lang.util-lang-en li:nth-child(3) a:before {
	content: '簡体字';
}
:lang(ja) .util-lang li:nth-child(1) a, :lang(en) .util-lang li:nth-child(2) a, :lang(zh) .util-lang li:nth-child(3) a {
	color: #333333;
	background-color: #ffffff;
	pointer-events: none;
}
.util-lang ul li {
	width: 33.333%;
	padding: 0 4px;
	margin-right: 0;
}
.util-lang ul li a {
	font-size: 1.4rem;
	color: #ffffff;
	width: auto;
	padding: 10px 0;
	border: 1px solid #ffffff;
	background-color: transparent;
}
}
.util-search {
}
.util-search legend {
	display: none;
}
.util-search input[type="text"] {
	padding: 3px;
	width: 160px;
	height: 24px;
	margin-right: 10px;
	outline: none;
	border: 1px solid #727171;
	vertical-align: top;
	-webkit-appearance: none;
	border-radius: 0;
}
.util-search button {
	width: 30px;
	height: 24px;
	border: none;
	outline: none;
	background-color: #ffffff;
	cursor: pointer;
	margin-left: -10px;
}
.util-search button::before {
	font-family: 'fontello';
	content: '\e801';
	font-size: 1.6rem;
	color: #727171;
}

@media screen and (max-width: 767px) {
.util-search input[type="text"] {
	font-size: 1.6rem;
	width: calc( 100% - 70px);
	height: 46px;
	margin-right: 10px;
}
.util-search button {
	width: 60px;
	height: 46px;
	background: #ffffff;
	margin-left: 0px;
}
.util-search button::before {
	content: '検索';
	font-size: 1.4rem;
	color: #333;
	letter-spacing: 3px;
	padding-left: 3px;
}
.util-search.util-search-en button::before {
	content: 'Search';
	font-size: 1.3rem;
	letter-spacing: 0px;
	padding-left: 2px;
}
.util-search.util-search-cn button::before {
	content: '搜索';
}
}
#nav {
	max-width: 957px;
	margin-left: auto;
	margin-right: auto;
	clear: both;
}
.nav-global {
	display: -webkit-flex; /* Safari */
	display: flex;
  justify-content: flex-end;
}
.nav-global > li {
	width: 16.5%;
	text-align: center;
	border-left: 1px solid #B5B5B5;
	position: relative;
}
.nav-global > li:nth-child(8) {
	border-right: 1px solid #B5B5B5;
}
.nav-global > li:nth-child(n+8) {
	display: none;
}

// 問い合わせのスタイル
.nav-global > li:last-child {
	display: block;
  border-right: 1px solid #B5B5B5;
}
.nav-global a {
	line-height: 1.1;
	display: block;
	padding: 5px 3px;
	letter-spacing: 1px;
}
.nav-global .nav-global-sub {
	display: none;
}


@media screen and (min-width: 768px) {
.nav-global > li > a:hover, .nav-global > li.current > a, .nav-global > li.open > a {
	color: #00a088;
}
.nav-global > li > a:hover::after,
.nav-global > li.current > a::after,
.nav-global > li.open > a::after {
	content: "";
	position: absolute;
	bottom: -5px;
	left: 20%;
	right: 20%;
	height: 3px;
	background-color: #00a088;
}
}

@media screen and (max-width: 767px) {
.nav-global {
	display: block;
}
.nav-global > li {
	width: 100%;
	text-align: left;
	border-left: none;
}
.nav-global > li:nth-child(6) {
	border-right: none;
}


.nav-global > li:nth-child(n+7) {
	display: block;
}
.nav-global .nav-global-list {
	border-bottom: 1px solid #727171;
	letter-spacing: 0px;
}
.nav-global a {
	color: #ffffff;
	text-decoration: none;
	position: relative;
	display: block;
	padding-top: 16px;
	padding-bottom: 16px;
	padding-left: 10px;
	padding-right: 40px;
	background-color: #595757;
}
.nav-global > li > a {
	font-weight: bold;
}
.nav-global .nav-global-sub li a {
	padding-left: 20px;
	letter-spacing: 0px;
}
.nav-global a::after {
	position: absolute;
	display: block;
	content: '';
	width: 20px;
	height: 20px;
	top: 50%;
	right: 10px;
	margin-top: -10px;
	overflow: hidden;
	background-image: url(../../common/img/icon_mob_menu.png);
	
	background-size: 20px 60px;
	background-position: 0 -20px;
}
.nav-global > li:not(.nav-global-list) > a::after, .nav-global .nav-global-sub li a::after {
	background-position: 0 0;
}
.nav-global .nav-global-list.open> a::after {
	background-position: 0 -40px;
}
}

.pdf-download {
  text-align: center;
  padding: 2em 0 0 0;
  .pdf-btn {
    a {
      border-radius: 6px;
      color: #fff;
      font-weight: bold;
      display: inline-block;
      // background-color: $color-harima-green;
      text-decoration: none;
      padding: 1em 2em;
      background: linear-gradient($color-harima-green,darken($color-harima-green, 7));
      &:active,
      &:hover {
        background: linear-gradient(darken($color-harima-green, 7),$color-harima-green);
      }

    }
  }
  aside {
    padding: 2em 0;
  }
  .adobe-reader-wrap {
    text-align: left;
  }
}

/* title ------------------------ */
.title-header-top {
	background: #F3F6F9;
	margin-bottom: 20px;
	line-height: 1;
}
.title-header-top span {
	max-width: 1000px;
	margin-left: auto;
	margin-right: auto;
}
.title-header-top img {
	width: 100%;
	height: auto;
}
.title-header {
	display: none;
}

@media screen and (max-width: 767px) {
.title-header {
	display: block;
	background: #00a088;
	color: #fff;
	font-size: 2.6rem;
	margin-bottom: 15px;
	line-height: 1;
	padding: 10px 15px;
	letter-spacing: 2px;
}
}
.title-header-s {
	color: #00a088;
	font-size: 3.0rem;
	margin-bottom: 20px;
	letter-spacing: 1px;
	line-height: 1.3;
}
.title-header-ss {
	background-color: #efefef;
	font-size: 2.0rem;
	margin-top: -10px;
	margin-bottom: 10px;
	letter-spacing: 1px;
	line-height: 1.2;
	border-left: 8px solid #00a088;
	padding: 10px 5px 9px 10px;
}
.title-header-sss {
	color: #00a088;
	font-size: 1.8rem;
	margin-bottom: 15px;
	padding-bottom: 3px;
	padding-right: 15px;
	line-height: 1.3;
	border-bottom: 1px solid #00a088;
}
.title-header-ssss {
	font-size: 1.6rem;
	font-weight: bold;
	margin-bottom: 10px;
	line-height: 1.4;
}
.title-header-s5 {
	font-size: 1.4rem;
	font-weight: normal;
	margin-bottom: 10px;
    line-height: 1.4;
    border-bottom: solid 1px #999;
}
.title-header-singlepage {
	background-color: #efefef;
	font-size: 2.0rem;
	margin-bottom: 10px;
	letter-spacing: 1px;
	padding: 10px 5px 9px 10px;
}
.title-header-singlepage span {
	display: block;
	max-width: 1000px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 15px;
	padding-right: 15px;
}
@media screen and (max-width: 767px) {
.title-header-s {
	font-size: 2.5rem;/*letter-spacing: 0px;*/
}
.title-header-ss {
	font-size: 1.7rem;
}
.title-header-sss {
	font-size: 1.7rem;
}
}
@import "../../css/harima-vars";

@charset "UTF-8";


/* breadcrumb-list ------------------------ */
.breadcrumb-list {
	margin-top: 15px;
	margin-bottom: 15px;
}
.breadcrumb-list li {
	display: inline;
}
.breadcrumb-list li::before {
	display: inline;
	font-family: 'fontello';
	content: '\f105';
	padding-left: 10px;
	margin-right: 10px;
	color: #999;
}
.breadcrumb-list li:first-child::before {
	display: none;
}
.breadcrumb-list li a {
	display: inline;
	padding: 2px 0px;
	text-decoration: none;
}
/* main ------------------------ */
.main {
	float: right;
	width: 74%;
	margin-bottom: 35px;
}

@media screen and (max-width: 767px) {
.main {
	float: none;
	width: 100%;
}
}
/* sidemenu ------------------------ */
.sidemenu {
	float: left;
	width: 24%;
	margin-bottom: 25px;
	line-height: 1.3;
}
.sidemenu a {
	text-decoration: none;
	display: block;
}
.sidemenu h3 {
	background-color: #00a088;
	font-size: 1.6rem;
}
.sidemenu h3 a {
	padding: 20px 3px;
	text-align: center;
	color: #fff;
}
.sidemenu h3 .nolink {
	text-decoration: none;
	display: block;
	padding: 20px 3px;
	text-align: center;
	color: #fff;
}
.nav-sidemenu > li {
	border-bottom: 1px solid #9fa0a0;
}
.nav-sidemenu > li > a {
	padding: 20px 0px 18px 18px;
	position: relative;
}
.nav-sidemenu > li > a::before {
	font-family: 'fontello';
	content: '\f105';
	position: absolute;
	left: 3px;
}
.nav-sidemenu > li.current > a, .nav-sidemenu > li.current-sub > a {
	color: #00a088;
}
.nav-sidemenu > li.current-sub {
	padding-bottom: 5px;
}
.nav-sidemenu > li.current-sub > a {
	padding-bottom: 13px;
}
.nav-sidemenu > li.current-sub > a::before {
	content: '\f107';
}
.current-sub li {
	border-bottom: none;
	margin-bottom: 10px;
}
.current-sub li a {
	padding: 5px 0px 5px 13px;
	position: relative;
	margin-left: 20px;
}
.current-sub li a::before {
	font-family: 'fontello';
	content: '-';
	position: absolute;
	left: 0px;
}
.current-sub li.current a {
	color: #00a088;
}

@media screen and (max-width: 767px) {
.sidemenu {
	float: none;
	width: 100%;
}
.sidemenu h3 a {
	text-align: left;
	padding-left: 15px;
}
.nav-sidemenu > li > a {
	padding: 20px 15px 18px;
}
.nav-sidemenu > li > a::before {
	display: none;
}
.nav-sidemenu > li > a::after {
	font-family: 'fontello';
	content: '\f105';
	position: absolute;
	right: 3px;
	top: 18px;
}
.nav-sidemenu > li.current-sub > a::after {
	content: '\f107';
}
}

/* --------------------------------
 * footer
 * -------------------------------- */
.footer {
	background-color: #00a088;
}
.footer-nav-wrap {
	display: -webkit-flex; /* Safari */
	display: flex;
	padding-top: 30px;
	padding-bottom: 10px;
	color: #fff;
}
.footer-nav {
	width: 25%;
	padding-right: 10px;
}
.footer-nav:last-of-type {
	padding-right: 0px;
}
.footer-nav ul {
	margin-bottom: 25px;
}
.footer-nav ul li {
	font-size: 1.3rem;
	font-weight: normal;
	letter-spacing: 0px;
}
.footer-nav > ul > li {
	font-size: 1.6rem;
	font-weight: bold;
	letter-spacing: 3px;
}
.footer-nav ul li a {
	color: #fff;
	text-decoration: none;
	padding: 3px 0 5px;
	display: block;
}
.footer-nav ul li a:hover {
	text-decoration: underline;
}
.footer-nav-util-wrap {
	max-width: 1000px;
	margin: 0 auto;
	padding: 0px 15px 30px 0px;
}
.footer-nav-util-wrap::after {
	content: "";
	display: block;
	clear: both;
}
.footer-nav-util {
	display: -webkit-flex; /* Safari */
	display: flex;
	/* float: left; */
	-webkit-flex-wrap: wrap; /* Safari */
	flex-wrap: wrap;
}
.footer-nav-util li {
	font-size: 1.2rem;
	line-height: 1;
	border-right: 1px solid #fff;
}
.footer-nav-util li:last-child {
	border-right: none;
}
.footer-nav-util li a {
	display: block;
	padding: 3px 15px;
	color: #fff;
	text-decoration: none;
	text-align: center;
	letter-spacing: 1px;
}
.footer-nav-util li a:hover {
	text-decoration: underline;
}
.copyright {
	font-size: 1.2rem;
	line-height: 1;
	text-align: right;
	padding: 3px 0px;
	color: #fff;
}

@media only screen and (max-width: 767px) {
.footer-nav-wrap {
	display: none;
}
.footer-nav-util-wrap {
	padding: 0px 0px 15px;
}
.footer-nav-util {
	float: none;
	margin-bottom: 15px;
}
.footer-nav-util li {
	width: 50%;
	font-size: 1.4rem;
	border-right: 1px solid #fff;
}
.footer-nav-util li:nth-child(even) {
	border-right: none;
}
.footer-nav-util li:last-child {
	/*
	Mobile layout nav odd items  -> 100%
	Even items -> 50%
	 */
	width: 100%;

}
.footer-nav-util li a {
	padding: 15px 5px;
	border-bottom: 1px solid #fff;
}
.copyright {
	text-align: center;
	padding: 0px 5px;
}

:lang(zh) {
  .footer-nav-util li:last-child {
  	width: 50%;
  }
}

:lang(en) {
  .footer-nav-util li:last-child {
  	width: 50%;
  }
}

}


/* Top ------------------------ */
.top-btn-wrap {
	display: -webkit-flex; /* Safari */
	display: flex;
	-webkit-flex-wrap: wrap; /* Safari */
	flex-wrap: wrap;
	-webkit-justify-content: space-between; /* Safari */
	justify-content: space-between;
}
.top-btn-wrap a {
	display: block;
	margin-bottom: 30px;
	text-decoration: none;
}
.top-btn-wrap a img {
	width: 100%;
	height: auto;
	margin-bottom: 5px;
}
.top-btn-wrap h3 {
	font-weight: bold;
	font-size: 1.6rem;
	line-height: 1.3;
	padding-left: 15px;
	position: relative;
	margin-bottom: 5px;
}
.top-btn-wrap h3::before {
	font-family: 'fontello';
	content: '\f105';
	position: absolute;
	left: 3px;
}
.top-btn-wrap01 a {
	width: 49.2%;
	margin-right: auto;
	margin-left: auto;
}
.top-btn-wrap02 a {
	width: 49.2%;
}
.top-btn-wrap03 a,
.top-btn-wrap03 .dummy-btn {
	width: 32.5%;
}
.top-btn-wrap04 a {
	width: 24%;
}
.top-btn-wrap-noimage a {

	border-radius: 6px;
}
.top-btn-wrap-noimage a,
.top-btn-wrap-noimage .dummy-btn {
	background-color: #efeee8;
	padding: 20px 25px 17px;
	margin-bottom: 20px;
}
.top-btn-wrap-noimage a:hover {
	background-color: #d7f3d0;
}

.dummy-btn {
	background: #fff !important;
}

@media screen and (max-width: 767px) {
.top-btn-wrap01 a, .top-btn-wrap02 a, .top-btn-wrap03 a {
	width: 100%;
}
.top-btn-wrap04 a {
	width: 49%;
}
.top-btn-wrap-noimage {
	
}
.top-btn-wrap-noimage a {
	background-color: #efeee8;
	padding: 15px 20px 12px;
	margin-bottom: 10px;
}
}
.top-btn-wrap.top-btn-wrap-left {
	-webkit-justify-content: flex-start; /* Safari */
	justify-content: flex-start;
}
.top-btn-wrap.top-btn-wrap-left.top-btn-wrap04 a {
	margin-right: 1.33%;
}
.top-btn-wrap.top-btn-wrap-left.top-btn-wrap04 a:nth-child(4n) {
	margin-right: 0%;
}
@media screen and (max-width: 767px) {
.top-btn-wrap.top-btn-wrap-left.top-btn-wrap04 a {
	margin-right: 0%;
}
}

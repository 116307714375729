.hq-body {
  p.interviewer {
    color: #666;
    // border-left: solid 10px #efefef;
    font-family: sans-serif;
    font-weight: bold;
    // font-size: 130%;
    padding: 0 0 0 0;
    margin-top: 2.5em;
    margin-bottom: 0.5em;
    // text-indent: -1.5em;
    @include mq{
      // padding: 0 0 0 1.5em;
    };
    &:before {
      content: "−−−−";
      letter-spacing: -0.2em;
      margin-right: 0.5em;
      font-weight: normal;
    }
  }
  .interviewee--mark {
    padding: 0.25rem 0.5rem;
    background-color: rgba($color:$color-harima-green, $alpha: .2);
    border: solid 1px $color-harima-green;
    font-family: sans-serif;
  }
}

// 研究者が複数（二人の場合）
.person--dobble {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  .person {
    position: relative;
    overflow: hidden;
    width: 100%;
    border-top: solid 1px $color-harima-green;
    border-left: solid 1px $color-harima-green;
    margin-left: 0;
    padding: 0.5rem 3rem;
    @include mq(){
      width: auto;
      margin-left: 1rem;
    }
    &::before {
      display: block;
      z-index: -10;
      position: absolute;
      top: -15px;
      left: -15px;
      content: " ";
      width:30px;
      height: 30px;
      background-color: $color-harima-green;
      transform: rotate(45deg);
    }
  }
}

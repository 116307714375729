@charset "UTF-8";
/* --------------------------------
 * object
 * -------------------------------- */
@import "object_title";
@import "object_col";
/* icon ------------------------ */
.icon-pdf::after, .icon-elsx::after, .icon-movie::after {
	margin-left: 8px;
	margin-right: 8px;
}
.icon-pdf::after {
	content: url(/common/img/icon_pdf_small.png);
}
.icon-elsx::after {
	content: url(/common/img/icon_elsx.png);
}
.icon-movie::after {
	content: url(/common/img/icon_movie.gif);
}
/*a[href^="//www"]::after {
	font-family: 'fontello';
	content: '\e802';
	margin-left: 5px;
}*/
/* adobe-reader ------------------------ */
.adobe-reader-wrap {
	margin-bottom: 30px;
}
.adobe-reader-description {
	margin-bottom: 10px;
}
.adobe-reader-banner {
	vertical-align: middle;
	margin-right: 10px;
}

@media screen and (max-width: 767px) {
.adobe-reader-banner {
	float: left;
}
}
/* .pager ------------------------ */
.pager {
	overflow: hidden;
	text-align: right;
	margin-bottom: 20px;
	font-size: 0;
}
.pager ul li {
	margin-right: 5px;
	display: inline-block;
}
.pager ul li span, .pager ul li a {
	display: block;
	padding: 0.4em 1em;
	border-radius: 2px;
	font-size: 1.2rem;
}
.pager ul li a {
	background: #ccc;
	color: #000;
	text-decoration: none;
}
.pager ul li a:hover {
	background: #727171;
	color: #FFF;
}
.pager02 {
	overflow: hidden;
	text-align: right;
	margin-bottom: 20px;
	font-size: 0;
}
.pager02 ul li {
	margin-right: 5px;
	display: inline-block;
}
.pager02 ul li a {
	display: block;
	padding: 0.4em 1em;
	border-radius: 2px;
	font-size: 1.2rem;
	text-decoration: none;
	background: #fff;
	color: #000;
}
.pager02 ul li a:hover, .pager02 ul li.current a {
	background-color: #00a088;
	color: #fff;
}
/* inner ------------------------ */
.inner {
	max-width: 1000px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 15px;
	padding-right: 15px;
}
.inner:after {
	content: "";
	display: block;
	clear: both;
}
/* clearfix ------------------------ */
.clearfix::after {
	content: "";
	display: block;
	clear: both;
}
/* for-pc, for-mobile ------------------------ */
.for-pc {
	display: block;
}
.for-mobile {
	display: none;
}

@media screen and (max-width: 767px) {
.for-pc {
	display: none;
}
.for-mobile {
	display: block;
}
}
/* image ------------------------ */
.flex-img img {
	width: 100%;
	height: auto;
}
.flex-img-for-pc img {
	width: 100%;
	height: auto;
}
.flex-img-for-mobile img {
	width: auto;
	height: auto;
}
@media screen and (max-width: 767px) {
.flex-img-for-pc img {
	width: auto;
	height: auto;
}
.flex-img-for-mobile img {
	width: 100%;
	height: auto;
}
}
.center-img, .center-img-large {
	text-align: center;
}
/*.center-img figure, .center-img-large figure {
	display: table;
	width: 120px;
	margin-left: auto;
	margin-right: auto;
}
@media screen and (max-width: 767px) {
.center-img figure, .center-img-large figure {
	width: 100%;
}
}*/
@media screen and (max-width: 767px) {
.center-img img {
	width: 100%;
	height: auto;
}
}
@media screen and (max-width: 900px) {
.center-img-large img {
	width: 100%;
	height: auto;
}
}

.center-img-max-with {
	margin-right: auto;
	margin-left: auto;
}
@media screen and (max-width: 900px) {
.center-img-max-with img {
	width: 100%;
	height: auto;
}
}


/* ul,ol ------------------------ */
.ul-restore {
	padding-left: 25px;
	list-style-type: disc;
}
.ol-restore {
	padding-left: 25px;
	list-style-type: decimal;
}
@import "object_utility";
@import "object_utility_mergin";

@import "numeric";
/* new_materials ------------------------ */
.hydrogen__table{
	font-size: 1.3rem;
}
.hydrogen_list{
	li{
	list-style: disc;
	margin-left: 30px;
}
}
.hydrogen__product{
	background-color: #DAE3F3;
	padding: 20px;
}
.hydrogen_title{
	text-align: center;
	color: #fff;
	background-color: #2F5597;
	border-radius: 3px;
	padding: 3px;
}
.hydrogen__info{
	display: flex;
	width: 100%;
	flex-wrap: nowrap;	
}
@media screen and (max-width: 768px) {
	.hydrogen__info{
	display: block !important;
}
}
.hydrogen__info--p{
	text-decoration: none;
	color: #2F5597;
	font-weight: bold;
	padding-left: 30px;
}
@media screen and (max-width: 768px) {
	.hydrogen__info--p{
	padding-left: 0px;
  }
}
.hydrogen__bold{
	font-weight: bold;
}
.embed--youtube--video{
	iframe{
	max-width:100%!important;
}
}

// covid-19
@import "object-covid19";

.list-mark--disk {
	padding-left: 2em;
	.item {
		list-style-type: disc;
	}
}

@import "emergency_announce";

// /assets/main

@import url("/assets/css/main.css")
@mixin btn-white-bordered--middle(){
    // ul > li > a
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    @include mq(){
        justify-content: space-between;
    }

    li {
        list-style: none;
        display: inline-block;
        width: 22%;
        margin-right: 3%;
        margin-bottom: 1.5em;
        padding: 0em 0 0 0;

        @include mq(){
            margin-right: 0;
            width: 48%;
        }

        a {
            text-decoration: none;
            display: inline-block;
            width: 100%;
            text-align: center;
            padding: .5em .1em;
            border: solid 1px $color-harima-green;
            border-radius: 3px;
            color: $color-harima-green;
            position: relative;
            &:hover,&:active {
                color: #fff;
                background-color: $color-harima-green;

            }
        }

    }


}

.outerreport {
    &__box {
        // border: solid 1px red;
    }
    &__list {
    @include btn-white-bordered--middle();

        &__item {

            a {
                &:hover,&:active{
                }
                
            }
        }
    }
}
.c-btn-white-bordered--middle {
    @include btn-white-bordered--middle();
}
// old

.title-environment {
  color: #ffffff;
  font-size: 2.0rem;
  margin-bottom: 20px;
  letter-spacing: 2px;
  line-height: 1;
  padding: 8px 20px; }

.title-environment-environment {
  background: #ABCD03; }

.title-environment-society {
  background: #5EB7E8; }

.web-column-list {
  li {
    position: relative;
    padding-bottom: 1em;

  }
  a {
    &:hover {
      background-color: rgba(#f4f6ec, 0.66);
    }
  }
  &--label {
    font-size: 2rem;
    display: block;
    color: #333;
    margin-bottom: 0.5em;
    // color: #009e86;
  }
  &--author {
    color: #333;
    display: block;
  }
  &--number {
    position: absolute;
    bottom: 0;
    right: 0;
  	display: block;
  	font-size: 1.4rem;
  	color: #999;
    margin-bottom: 8px;
    .season {
      width: 12rem;
      display: inline-block;
      text-align: right;
    }
  	// font-weight: bold;
    @media screen and (max-width: 767px){
      position: relative;
      .season {
        display: inline;
        text-align: left;
      }
    }
  }
  &--notice {
    color: #999;
    text-align: right;
  }

}


.web-column-list-number2 {
	display: block;
	font-size: 1.4rem;
	color: #999;
	margin-bottom: 8px;
	// font-weight: bold;
}
.web-column-list-number2::before {
}


.hq-profile {

  overflow: hidden;
  &:before {

    content: "";
    display: block;
    width: 20%;
    margin: 3em auto;
    border-top: solid 3px #ccc;

  }
  .awrds {
    border-top: solid 1px #999;
    display: inline-block;
    margin-top: 0.5em;
    padding-top: 0.5em;
  }
}
.hq-profile p {
  font-size: 3.5vw;
  text-align: justify;
  &.profile-lead {
    font-weight: bold;
    font-size: 5vw;
    font-feature-settings : "palt";
    padding: 0 0 0.5em 0;
  }
  @include mq{
    font-size: 1.0em;
    &.profile-lead {
      font-size: 1.6em;
    }
  };

}
.profileimg {
  width: 50%;
  float: left;
  margin-right: 3.5vw;
  img {
    width: 100%;
  }
}
.profileimg.layout-horizon {
  width: 100%;
  float: none;
}

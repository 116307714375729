.numeric {
    &--section{
        counter-reset: number;
    }
    &--number {
        &:before {
            counter-increment: number;
            content: counter(number) ". ";
        }
    }
}

.insertimg {
  // border-top: solid 5px #ccc;
  // border-bottom: solid 5px #ccc;
  width:  80%;
  margin: 2em  auto ;
  background: #efefef;
  padding: 1em;
  border-radius: 3px;
  overflow: hidden;
  img {
    width: 100%;
  }
  .caption {
    margin: 1em 2em;
    font-size: 3.5vw;
    font-family: sans-serif;
    line-height: 1.2em;
    text-align: left;
    // font-weight: bold;
  }
  @include mq {
    width:  40%;
    margin: 0 0 0 2em ;
    overflow: hidden;
    float: right;

    .caption {
      font-size: 1.0em;
      margin: 1em 0;
    }
    img {
      width: 100%;
      // margin-right: 3em;
      // float:left;
    }
  };
  &.insertimg-center {
    padding:0;
    background: none;
    width: 100vw;
    margin: 0 calc(50% - 50vw);
    @include mq {
      padding: 1em;
      background: #efefef;
      width: 70%;
      margin: 0 auto;
      float: none;
    };
  }
}

/* --------------------------------
 * utility
 * -------------------------------- */
/* color ------------------------ */
.u-color-keygreen {
	color: #00a088;
}
.u-color-red {
	color: #ff0000;
}
/* weight ------------------------ */
.u-weight-bold {
	font-weight: bold;
}
/* text-align ------------------------ */
.u-align-left {
	text-align: left;
}
.u-align-center {
	text-align: center;
}
.u-align-right {
	text-align: right;
}
/* size ------------------------ */
.u-fsize-10 {
	font-size: 1.0rem;
}
.u-fsize-12 {
	font-size: 1.2rem;
}
.u-fsize-13 {
	font-size: 1.3rem;
}
.u-fsize-16 {
	font-size: 1.6rem;
}
.u-fsize-18 {
	font-size: 1.8rem;
}
.u-fsize-22 {
	font-size: 2.2rem;
}

/* line-height ------------------------ */
.u-lh-1 {
	line-height: 1;
	&_wrap p {
		line-height: 1;
	}
}
.u-lh-1_5 {
	line-height: 1.5;
	&_wrap p {
		line-height: 1.5;
	}
}
.u-lh-1_8 {
	line-height: 1.8;
	&_wrap p {
		line-height: 1.8;
	}
}
.u-lh-2 {
	line-height: 2;
	&_wrap p {
		line-height: 2;
	}
}

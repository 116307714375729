
.page-error {
    .breadcrumb-list,
    .title-header-singlepage {
        display: none;
    }
    .helo {
        position: relative;
        min-height: 400px;
        text-align: center;
        font-size: 48px;
        padding-top:100px;
        @include mq{
            padding-top: 50px;
            min-height: 290px;
            font-size: 24px;
        }
        h1,p {
            position: relative;
            // z-index: 100;
        }
        .error-message {
            font-size: 16px;
            margin-bottom: 100px;
        }
        &:before {
            // z-index: 10;
            content: "";
            display: block;
    
            top: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            background: url(/img/mainvidual_desktop.jpg) center top no-repeat;
            background-position-y: -60px;
            background-size: cover;
            opacity: .2;
        }
    .inner {
        max-width: none;
        padding: 0;
        &::before {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            background-image: linear-gradient(rgba(#fff,0),white);
            height: 200px;
            width: 100%;
        }
    }

    }
    .error-top {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 60px;
        margin-bottom: 150px;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        // padding: 5rem 0;
        @include mq {
            margin-top: 30px;
            margin-bottom: 70px;
        }
        .item {
            padding: 2rem 0;
            @include mq {
                padding: 1rem 0;
                margin-bottom: 20px;
                &:nth-last-child{
                    margin-bottom: 0;
                }
            }
            a {
                width: 190px;
                margin: 0 20px;

                @include link-text-box-r;
                display: inline-block;
                    color: $color-text-white;
                text-decoration: none;
                &:active,&:hover {
                    color: $color-harima-green;
                }
            }
        }
    }
}
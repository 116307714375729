.underconstruction {
    &--wrapper {
        display: flex;
        height: 40vh;
        align-items: center;
    }
    &--inner {
        width: 100%;

    }
    &--message {
        text-align: center;
        font-size: 1.6em;
        color: darken($color: $color-harima-green, $amount: 10%);
    }
}
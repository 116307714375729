.hq-article-info {
    border-top: solid 1px #ccc; padding-top: 1em;
    text-align: center;
    a {
        text-decoration: none;
            .web-column-list--number {
        position:relative;
        .label_author {
            display: block;
        }
    }
    }

}
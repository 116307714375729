._p-cookiepolicy {
    // background-color: red;
    h3 {
        font-size: 1.6em;
        padding-bottom: 0.5em;
        margin-bottom: 1em;
        border-bottom: solid 1px $color-harima-green;
    }
    p {
        margin-bottom: 1em;
    }
    ul {
        margin-bottom: 1em;
        padding-left: 3em;
        .item {
            list-style: disc;
        }
    }
}
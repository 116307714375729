
@media screen and (min-width:768px) {
  // Reiwa style
  $pcsubmenu-radius: 3px;
	.nav-global {
		li:first-child {
			display: none;
		}
	}
  .nav-global-en {
    li:first-child {
      display: table-cell;
    }
  }
  .nav-global-cn {
    li:first-child {
      display: table-cell;
    }
  }
  .nav-global .open .nav-global-sub {
    &--opend {
      display: block;
      background-color: transparent;
      // box-shadow: 0 10px 3px 0px rgba(0,0,0,1);
      margin-top: calc(0px);
      padding-top: calc(0px);
      z-index: 999;
      position: absolute;
      text-align: left;
      width: 300px;
      border: solid 2px #fff;
      border-top:none;
      // border-radius: $pcsubmenu-radius;

      li:first-child {
        display: none;

      }
      li:last-child {
        // border-radius:0 0 $pcsubmenu-radius $pcsubmenu-radius;
      }
      li:nth-child(2) {
        margin-top: 10px;
        // border-radius:$pcsubmenu-radius $pcsubmenu-radius 0 0 ;
      }
      li {
        background-color: #ddd;
        border-top: solid 1px #eee;
        // font-size: 80%;
        a {
          padding: 8px;
          display: block;
          letter-spacing: 0px;
          // transition: background-color 0.2s linear;
          &:hover,&:active {
            background-color: $color-harima-green;
            color:#fff;
            // border-radius: $pcsubmenu-radius;
            transition: background-color 0.2s linear;
          }
        }
      }

    }
  }

}


/* --------------------------------
 * EN-CN
 * -------------------------------- */
header.header-en {
	height: 120px;
}
@media screen and (max-width: 767px) {
header.header-en {
	height: 60px;
}
}

:lang(en) .util-inquiry {
	display: none;
}
:lang(zh) .util-inquiry {
	display: none;
}

.nav-global.nav-global-en {
	display: table;
}
.nav-global.nav-global-en > li {
  width: 16%;
  display: table-cell;
	vertical-align: middle;
	text-align: center;
}
.nav-global.nav-global-cn > li {
	width: 16%;
	display: table-cell;
	vertical-align: middle;
	text-align: center;
}
.nav-global.nav-global-en > li:nth-child(6), .nav-global.nav-global-cn > li:nth-child(6) {
	border-right: none;
}
.nav-global.nav-global-en > li:nth-child(5), .nav-global.nav-global-cn > li:nth-child(5) {
	border-right: 1px solid #B5B5B5;
}
.nav-global.nav-global-en > li:nth-child(n+6), .nav-global.nav-global-cn > li:nth-child(n+6) {
	display: none;
}
// 英語の問い合わせ

.nav-global.nav-global-en > li:nth-child(5) {
  border-right: none;
}

.nav-global.nav-global-en > li:last-child {
	display: table-cell;
}
.nav-global.nav-global-cn > li:last-child {
	display: table-cell;
}
.nav-global.nav-global-en a {
	line-height: 1.2;
	letter-spacing: 1px;
}

@media screen and (max-width: 767px) {
.nav-global.nav-global-en {
	display: block;
}
.nav-global.nav-global-en > li, .nav-global.nav-global-cn > li {
	width: 100%;
	display: block;
	vertical-align: middle;
	text-align: left;
}
.nav-global.nav-global-en > li:nth-child(5), .nav-global.nav-global-cn > li:nth-child(5) {
	border-right: none;
}
.nav-global.nav-global-en > li:nth-child(n+6), .nav-global.nav-global-cn > li:nth-child(n+6) {
	display: block;
}
}
.footer-nav.footer-nav-en > ul > li {
	letter-spacing: 2px;
}

.hq-pager {
  border-top: solid 1px #ccc;
  clear:both;
  margin: 30px 0;
  padding: 15px 0;
  .page-next-topic {
    color: #999;
    text-align: center;
    font-size: 1.3em;
    a {
      display: inline-block;
      text-decoration: none;
      border-bottom: solid 2px $color-harima-green;
      // border-radius: 9px;
      padding: 0.5em 0.3em;
      // margin: 2em 0;
      @include mq{
        display: inline;
      };
    }
    margin: 20px 0;
  }
  .pager-numbers {
    margin: 2em 0;
    display: flex;
    justify-content: space-around;
    // justify-content: center;
    a {
      padding: 5px 0 2px 0;
      margin: 0 5px;
      font-size: 100%;
      font-weight: bold;
      border-bottom: solid 2px $color-harima-green;
      text-align: center;
      display: block;
      min-width: 30px;
      min-height: 25px;
      text-decoration: none;
      &.current {
        color: white;
        border-radius: 3px;
        background-color: $color-harima-green;
        &:active,
        &:hover {
          background-color: darken($color-harima-green, 5);
        }
      }
      &:active,
      &:hover {
        color: white;
        border-radius: 3px;
        background-color: $color-harima-green;
      }
    }
    @include mq{
      justify-content: center;
    };
  }
}

@import "../../css/harima-vars";
.emergency-announce{
	max-width: 1000px;
    margin: 2rem auto;
    color: #fff;
	background-color: #A00018;
    font-size: 1.3em;
	.inner {
		padding: 1.5rem 3rem;
	}
	a {
		display: block;
		position: relative;
		vertical-align: middle;
		text-align: center;
		color:#fff ;
	}
}

.emergency-announce a:link, .emergency-announce a:visited {
    text-decoration: none;
}

.emergency-announce a:before {
    font-family: 'fontello';
    content: '\f105 ';
    font-size: 2rem;
    text-decoration: none;
	padding-right: 0.5rem;
}

.emergency-announce_underbar{
    text-decoration:underline;
    text-decoration-color:#fff;
}
@mixin mq(){
  @media screen and (min-width: 768px) { // 768px を超える場合は以下の部分が適用
    @content
  }
}

$basefont: "メイリオ","ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;

// base font-size
$fs-title: 7vw;

// base color
$color-harima-green: #00a088;



.title-header {
  display: none;
}

 .diabled{
     &--anker {

     }
 }

//  nav-sidemenue

.nav-sidemenu {
    li {
        .diabled--anker {
            padding: 20px 0px 18px 18px;
            position: relative;
            display: block;
            text-decoration: none;
            color: #999;
        }
    }
}
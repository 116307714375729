@charset "utf-8";

@import "../../harima-vars";


/* reset */
.cookie-dialog,
.cookie-dialog *{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
.cookie-dialog::before,
.cookie-dialog::after{
    box-sizing: inherit;
}


.cookie-dialog{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba($color-harima-green, 0.9);
    z-index: 2147483647;
    transition: all 300ms ease-in-out;
    /*font-size: 140%;*/    /* this site only Setting! */
    /*line-height: 160%;*/  /* this site only Setting! */
    font-size: 13px;    /* this site only Setting! */
    line-height: 1.5;  /* this site only Setting! */
}
.cookie-dialog.hidden{
    visibility: hidden;
    pointer-events: none;
    opacity: 0.0;
    transform: translateY(100%);
}
.cookie-dialog__inr{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}
.cookie-dialog__msg{
    padding-right: 20px;
    color: #fff;
    a {
        color: #fff;
        font-weight: bold;
        text-decoration: underline;
        margin-left: 5px;
        margin-right: 5px;
        &:hover,&:active {
            color: #666;
        }
    }
}
.cookie-dialog__link{
    color: #fff !important;
    text-decoration: underline !important;
}
.cookie-dialog__btn{
    flex: 0 0 auto;
    /*align-self: stretch;*/
    width: 200px;
    height: 40px;
    border-radius: 6px;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    overflow: visible; /* ie11 */

    font-family : inherit;
    /*font-size : 100%;*/
				font-size: 13px;
    color: #000;
}
.cookie-dialog__btn:active{
    color: #000;
}
@media screen and (max-width:767px) {
    .cookie-dialog__inr{
        flex-direction: column;
        padding: 15px;
    }
    .cookie-dialog__msg{
        margin-bottom: 15px;
    }
    .cookie-dialog__btn{
       width: 100%;
    }
}



/* 閉じるボタン追加 */

.cookie-dialog-inner{position: relative;}
.cookie-dialog-input{display: none;}
.cookie-dialog-input:checked ~ .cookie-dialog {
visibility: hidden;
pointer-events: none;
opacity: 0.0;
transform: translateY(100%);
}
.cookie-dialog-inner label{
background-position:center center;
background: rgba($color-harima-green, 0.9);
display: flex;
justify-content: center;
align-items: center;
width: 40px;
height: 24px;
border-top-left-radius: 10px;
border-top-right-radius: 10px;

color: #ffffff;
font-weight: bold;
position: absolute;
cursor: pointer;
cursor: hand;
top: -24px;
right: 1.5%;
}
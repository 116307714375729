
.hq-body,
.hq-profile {
  margin-top: 20px;
  font-family: $basefont;
}

.hq-body h4 {
  font-weight: bold;
  font-size: 6vw;
  margin-bottom: 3vw;
  margin-top: 3vw;
  @include mq{
    font-size: 1.4em;
    margin-top: 0.7em;
    margin-bottom: 0.7em;
  };
}
.hq-body p {
  font-size: 4.5vw;
  font-family: $basefont;
  line-height: 1.7em;
  margin-bottom: 4.5vw;
  text-align: justify;
  // transform: rotate(0.1deg);
  // font-weight: 600;
  @include mq{
    font-size: 1.2em;
    margin-top: 0.7em;
    margin-bottom: 0.7em;
  };
}

@font-face {
    font-family: 'fontello';
    src: url('/common/font/fontello.eot?76404765');
    src: url('/common/font/fontello.eot?76404765#iefix') format('embedded-opentype'),
         url('/common/font/fontello.woff2?76404765') format('woff2'),
         url('/common/font/fontello.woff?76404765') format('woff'),
         url('/common/font/fontello.ttf?76404765') format('truetype'),
         url('/common/font/fontello.svg?76404765#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  /* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
  /* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
  /*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'fontello';
      src: url('/common/font/fontello.svg?76404765#fontello') format('svg');
    }
  }
  */
   
   [class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
   
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */
   
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
   
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
   
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;
   
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
   
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
   
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  }
   
  .icon-mail:before { content: '\e800'; } /* '' */
  .icon-search:before { content: '\e801'; } /* '' */
  .icon-blink:before { content: '\e802'; } /* '' */
  .icon-angle-left:before { content: '\f104'; } /* '' */
  .icon-angle-right:before { content: '\f105'; } /* '' */
  .icon-angle-up:before { content: '\f106'; } /* '' */
  .icon-angle-down:before { content: '\f107'; } /* '' */
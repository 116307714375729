@import "../../css/harima-vars";

.page-covid19 {
    .title-header-singlepage {
        background-color: $color-harima-green;
        color: #fff;
    }
}

.covid19-topics {
    border: none;
    .inner {

    }
    article {
        border-bottom: solid 1px #ccc;
        padding: 1.5rem 3rem;
        a {
            display: flex;
            time {
                margin-right: 2rem;
            }
            &:link,&:visited{
                text-decoration: none;
            }
        }
    }
}

// top page emergency news area

.emergency-news {
    width: 100%;
    margin-bottom: 3rem;
    background: #fff;
    border: solid 1px rgba($color-harima-green,0.2);
    font-size: 1.3em;
    .inner {
        padding: 1.5rem 3rem;
    }
    a {
        display: block;
        position: relative;
        padding-left: 15px;
        vertical-align: middle;
        &:before {
            font-family: 'fontello';
            content: '\f105  ';
            
            font-size: 2rem;
            text-decoration: none;
        }
        &:link,&:visited{
            text-decoration: none;
        }
        &:active,&:hover{
            text-decoration: underline;
        }
    }
}
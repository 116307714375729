@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("/assets/css/main.css");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, main, menu, nav, section {
  display: block; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote::before, blockquote::after,
q::before, q::after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* --------------------------------
 * base
 * -------------------------------- */
html {
  font-size: 62.5%; }

body {
  background-color: #ffffff;
  color: #333;
  /*font: 1.4rem/1.5 '游ゴシック体','Yu Gothic',YuGothic,'ヒラギノ角ゴシック Pro','Hiragino Kaku Gothic Pro',メイリオ,Osaka,'ＭＳ Ｐゴシック','MS PGothic',Arial,Helvetica,sans-serif;*/
  /*font-family: '游ゴシック体', 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', Arial, Helvetica, sans-serif;*/
  /*for IE9*/
  font-family: 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', Arial, Helvetica, sans-serif;
  font-size: 1.4rem;
  /*for IE9*/
  line-height: 1.5;
  /*for IE9*/
  -webkit-text-size-adjust: none; }

*, *::before, *::after {
  box-sizing: border-box; }

a {
  color: #333; }

a:hover {
  color: #00a088; }

a[href^="http"] {
  word-wrap: break-word; }

ruby rp, ruby rt {
  display: inline;
  font-size: 1.4rem; }

/* page-top ------------------------ */
#page-top {
  width: 58px;
  height: 58px;
  right: 30px;
  bottom: 20px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  font-family: 'fontello';
  font-size: 4.0rem;
  /*line-height: 54px;*/
  line-height: 50px;
  display: none;
  background-color: rgba(150, 150, 150, 0.85);
  border-radius: 50%;
  position: fixed;
  z-index: 99999;
  border: 2px solid #FFFFFF; }

/* reset */
.cookie-dialog,
.cookie-dialog * {
  box-sizing: border-box;
  padding: 0;
  margin: 0; }

.cookie-dialog::before,
.cookie-dialog::after {
  box-sizing: inherit; }

.cookie-dialog {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 160, 136, 0.9);
  z-index: 2147483647;
  transition: all 300ms ease-in-out;
  /*font-size: 140%;*/
  /* this site only Setting! */
  /*line-height: 160%;*/
  /* this site only Setting! */
  font-size: 13px;
  /* this site only Setting! */
  line-height: 1.5;
  /* this site only Setting! */ }

.cookie-dialog.hidden {
  visibility: hidden;
  pointer-events: none;
  opacity: 0.0;
  transform: translateY(100%); }

.cookie-dialog__inr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px; }

.cookie-dialog__msg {
  padding-right: 20px;
  color: #fff; }
  .cookie-dialog__msg a {
    color: #fff;
    font-weight: bold;
    text-decoration: underline;
    margin-left: 5px;
    margin-right: 5px; }
    .cookie-dialog__msg a:hover, .cookie-dialog__msg a:active {
      color: #666; }

.cookie-dialog__link {
  color: #fff !important;
  text-decoration: underline !important; }

.cookie-dialog__btn {
  flex: 0 0 auto;
  /*align-self: stretch;*/
  width: 200px;
  height: 40px;
  border-radius: 6px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  overflow: visible;
  /* ie11 */
  font-family: inherit;
  /*font-size : 100%;*/
  font-size: 13px;
  color: #000; }

.cookie-dialog__btn:active {
  color: #000; }

@media screen and (max-width: 767px) {
  .cookie-dialog__inr {
    flex-direction: column;
    padding: 15px; }
  .cookie-dialog__msg {
    margin-bottom: 15px; }
  .cookie-dialog__btn {
    width: 100%; } }

/* 閉じるボタン追加 */
.cookie-dialog-inner {
  position: relative; }

.cookie-dialog-input {
  display: none; }

.cookie-dialog-input:checked ~ .cookie-dialog {
  visibility: hidden;
  pointer-events: none;
  opacity: 0.0;
  transform: translateY(100%); }

.cookie-dialog-inner label {
  background-position: center center;
  background: rgba(0, 160, 136, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 24px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #ffffff;
  font-weight: bold;
  position: absolute;
  cursor: pointer;
  cursor: hand;
  top: -24px;
  right: 1.5%; }

.hq-article-info {
  border-top: solid 1px #ccc;
  padding-top: 1em;
  text-align: center; }
  .hq-article-info a {
    text-decoration: none; }
    .hq-article-info a .web-column-list--number {
      position: relative; }
      .hq-article-info a .web-column-list--number .label_author {
        display: block; }

.outerreport__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  @media screen and (max-width: 767px) {
    .outerreport__list {
      justify-content: space-between; } }
  .outerreport__list li {
    list-style: none;
    display: inline-block;
    width: 22%;
    margin-right: 3%;
    margin-bottom: 1.5em;
    padding: 0em 0 0 0; }
    @media screen and (max-width: 767px) {
      .outerreport__list li {
        margin-right: 0;
        width: 48%; } }
    .outerreport__list li a {
      text-decoration: none;
      display: inline-block;
      width: 100%;
      text-align: center;
      padding: .5em .1em;
      border: solid 1px #00a088;
      border-radius: 3px;
      color: #00a088;
      position: relative; }
      .outerreport__list li a:hover, .outerreport__list li a:active {
        color: #fff;
        background-color: #00a088; }

.c-btn-white-bordered--middle {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  @media screen and (max-width: 767px) {
    .c-btn-white-bordered--middle {
      justify-content: space-between; } }
  .c-btn-white-bordered--middle li {
    list-style: none;
    display: inline-block;
    width: 22%;
    margin-right: 3%;
    margin-bottom: 1.5em;
    padding: 0em 0 0 0; }
    @media screen and (max-width: 767px) {
      .c-btn-white-bordered--middle li {
        margin-right: 0;
        width: 48%; } }
    .c-btn-white-bordered--middle li a {
      text-decoration: none;
      display: inline-block;
      width: 100%;
      text-align: center;
      padding: .5em .1em;
      border: solid 1px #00a088;
      border-radius: 3px;
      color: #00a088;
      position: relative; }
      .c-btn-white-bordered--middle li a:hover, .c-btn-white-bordered--middle li a:active {
        color: #fff;
        background-color: #00a088; }

/* breadcrumb-list ------------------------ */
.breadcrumb-list {
  margin-top: 15px;
  margin-bottom: 15px; }

.breadcrumb-list li {
  display: inline; }

.breadcrumb-list li::before {
  display: inline;
  font-family: 'fontello';
  content: '\f105';
  padding-left: 10px;
  margin-right: 10px;
  color: #999; }

.breadcrumb-list li:first-child::before {
  display: none; }

.breadcrumb-list li a {
  display: inline;
  padding: 2px 0px;
  text-decoration: none; }

/* main ------------------------ */
.main {
  float: right;
  width: 74%;
  margin-bottom: 35px; }

@media screen and (max-width: 767px) {
  .main {
    float: none;
    width: 100%; } }

/* sidemenu ------------------------ */
.sidemenu {
  float: left;
  width: 24%;
  margin-bottom: 25px;
  line-height: 1.3; }

.sidemenu a {
  text-decoration: none;
  display: block; }

.sidemenu h3 {
  background-color: #00a088;
  font-size: 1.6rem; }

.sidemenu h3 a {
  padding: 20px 3px;
  text-align: center;
  color: #fff; }

.sidemenu h3 .nolink {
  text-decoration: none;
  display: block;
  padding: 20px 3px;
  text-align: center;
  color: #fff; }

.nav-sidemenu > li {
  border-bottom: 1px solid #9fa0a0; }

.nav-sidemenu > li > a {
  padding: 20px 0px 18px 18px;
  position: relative; }

.nav-sidemenu > li > a::before {
  font-family: 'fontello';
  content: '\f105';
  position: absolute;
  left: 3px; }

.nav-sidemenu > li.current > a, .nav-sidemenu > li.current-sub > a {
  color: #00a088; }

.nav-sidemenu > li.current-sub {
  padding-bottom: 5px; }

.nav-sidemenu > li.current-sub > a {
  padding-bottom: 13px; }

.nav-sidemenu > li.current-sub > a::before {
  content: '\f107'; }

.current-sub li {
  border-bottom: none;
  margin-bottom: 10px; }

.current-sub li a {
  padding: 5px 0px 5px 13px;
  position: relative;
  margin-left: 20px; }

.current-sub li a::before {
  font-family: 'fontello';
  content: '-';
  position: absolute;
  left: 0px; }

.current-sub li.current a {
  color: #00a088; }

@media screen and (max-width: 767px) {
  .sidemenu {
    float: none;
    width: 100%; }
  .sidemenu h3 a {
    text-align: left;
    padding-left: 15px; }
  .nav-sidemenu > li > a {
    padding: 20px 15px 18px; }
  .nav-sidemenu > li > a::before {
    display: none; }
  .nav-sidemenu > li > a::after {
    font-family: 'fontello';
    content: '\f105';
    position: absolute;
    right: 3px;
    top: 18px; }
  .nav-sidemenu > li.current-sub > a::after {
    content: '\f107'; } }

/* --------------------------------
 * header
 * -------------------------------- */
header {
  position: relative;
  height: 100px;
  max-width: 1000px;
  margin: 0 auto 10px;
  padding-left: 15px;
  padding-right: 15px; }

header a {
  text-decoration: none; }

header .header-inner {
  position: relative;
  height: 75px;
  background-color: #ffffff; }

header .logo {
  position: absolute;
  width: 260px;
  top: 10px;
  left: 0; }

header .logo img {
  width: 100%; }

@media screen and (max-width: 767px) {
  header {
    position: fixed;
    width: 100%;
    height: 60px;
    padding-left: 0;
    padding-right: 0;
    z-index: 9999; }
  header .header-inner {
    height: 60px; }
  header .header-inner:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    top: 60px; }
  header .logo {
    width: 180px;
    top: 13px;
    left: 15px; } }

@media screen and (max-width: 374px) {
  header .logo {
    width: 160px;
    top: 13px;
    left: 15px; } }

header .mobile-btn {
  display: none; }

@media screen and (max-width: 767px) {
  header .mobile-btn {
    display: block;
    position: absolute;
    top: 0;
    right: 0; }
  header .mobile-btn li {
    float: left;
    border-left: 1px solid #9fa0a0; }
  header .mobile-btn button {
    display: block;
    width: 50px;
    height: 60px;
    padding: 5px 0 0;
    border: none;
    background-color: #ffffff;
    cursor: pointer;
    outline: none; }
  header .mobile-btn button span {
    display: inline-block;
    width: 40px;
    height: 50px;
    overflow: hidden;
    text-indent: -9999px;
    background-image: url(/assets/img/icon_mob_menu2.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 80px 250px; }
  header .mobile-btn button.btn-menu span {
    background-position: 0 0; }
  header .mobile-btn button.btn-search span {
    background-position: 0 -50px; }
  :lang(ja) header .mobile-btn button.btn-lang span {
    background-position: 0 -100px; }
  :lang(en) header .mobile-btn button.btn-lang span {
    background-position: 0 -150px; }
  :lang(zh) header .mobile-btn button.btn-lang span {
    background-position: 0 -200px; }
  header .mobile-btn button.active {
    color: #ffffff;
    background-color: #595757; }
  header .mobile-btn button.active.btn-menu span {
    background-position: -40px 0; }
  header .mobile-btn button.active.btn-search span {
    background-position: -40px -50px; }
  :lang(ja) header .mobile-btn button.active.btn-lang span {
    background-position: -40px -100px; }
  :lang(en) header .mobile-btn button.active.btn-lang span {
    background-position: -40px -150px; }
  :lang(zh) header .mobile-btn button.active.btn-lang span {
    background-position: -40px -200px; } }

@media screen and (max-width: 374px) {
  header .mobile-btn button {
    width: 44px; } }

.nav-util {
  position: absolute;
  top: 25px;
  right: 15px; }

.nav-util:after {
  content: '';
  display: block;
  clear: both; }

.util-inquiry, .util-lang, .util-search {
  float: left; }

.util-inquiry, .util-lang {
  margin-right: 10px; }

:lang(ja) .util-inquiry {
  display: none; }

@media screen and (max-width: 767px) {
  #nav, .util-lang, .util-search {
    visibility: hidden;
    opacity: 0;
    /*transition: all 300ms;*/
    float: none;
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc( 100% - 60px);
    margin-right: 0;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9999; }
  #nav.open, .util-lang.open, .util-search.open {
    visibility: visible;
    opacity: 1;
    transition: all 300ms; }
  .gl-item {
    padding: 15px 10px;
    background-color: #595757; } }

.util-inquiry a {
  font-size: 1.2rem;
  display: block;
  padding: 3px 0; }

.util-inquiry a::before {
  font-family: 'fontello';
  content: '\e800';
  margin-right: 3px; }

@media screen and (max-width: 767px) {
  .util-inquiry {
    display: none; } }

.util-lang li:nth-child(1) a:before {
  content: 'JP'; }

.util-lang li:nth-child(2) a:before {
  content: 'EN'; }

.util-lang li:nth-child(3) a:before {
  content: 'CN'; }

:lang(ja) .util-lang li:nth-child(1) a, :lang(en) .util-lang li:nth-child(2) a, :lang(zh) .util-lang li:nth-child(3) a {
  color: #ffffff;
  background-color: #727171;
  pointer-events: none; }

.util-lang ul:after {
  content: '';
  display: block;
  clear: both; }

.util-lang li {
  float: left;
  margin-right: 1px; }

.util-lang li a {
  font-size: 1.2rem;
  text-align: center;
  display: block;
  padding: 3px 25px;
  background-color: #cccccc; }

.util-lang li a:hover {
  color: #ffffff;
  background-color: #727171; }

@media screen and (max-width: 900px) {
  .util-lang li a {
    padding: 3px 15px; } }

@media screen and (max-width: 767px) {
  .util-lang li:nth-child(1) a:before {
    content: '日本語'; }
  .util-lang li:nth-child(2) a:before {
    content: 'English'; }
  .util-lang li:nth-child(3) a:before {
    content: '簡体字'; }
  .util-lang.util-lang-en li:nth-child(1) a:before {
    content: '日本語'; }
  .util-lang.util-lang-en li:nth-child(2) a:before {
    content: 'English'; }
  .util-lang.util-lang-en li:nth-child(3) a:before {
    content: '簡体字'; }
  :lang(ja) .util-lang li:nth-child(1) a, :lang(en) .util-lang li:nth-child(2) a, :lang(zh) .util-lang li:nth-child(3) a {
    color: #333333;
    background-color: #ffffff;
    pointer-events: none; }
  .util-lang ul li {
    width: 33.333%;
    padding: 0 4px;
    margin-right: 0; }
  .util-lang ul li a {
    font-size: 1.4rem;
    color: #ffffff;
    width: auto;
    padding: 10px 0;
    border: 1px solid #ffffff;
    background-color: transparent; } }

.util-search legend {
  display: none; }

.util-search input[type="text"] {
  padding: 3px;
  width: 160px;
  height: 24px;
  margin-right: 10px;
  outline: none;
  border: 1px solid #727171;
  vertical-align: top;
  -webkit-appearance: none;
  border-radius: 0; }

.util-search button {
  width: 30px;
  height: 24px;
  border: none;
  outline: none;
  background-color: #ffffff;
  cursor: pointer;
  margin-left: -10px; }

.util-search button::before {
  font-family: 'fontello';
  content: '\e801';
  font-size: 1.6rem;
  color: #727171; }

@media screen and (max-width: 767px) {
  .util-search input[type="text"] {
    font-size: 1.6rem;
    width: calc( 100% - 70px);
    height: 46px;
    margin-right: 10px; }
  .util-search button {
    width: 60px;
    height: 46px;
    background: #ffffff;
    margin-left: 0px; }
  .util-search button::before {
    content: '検索';
    font-size: 1.4rem;
    color: #333;
    letter-spacing: 3px;
    padding-left: 3px; }
  .util-search.util-search-en button::before {
    content: 'Search';
    font-size: 1.3rem;
    letter-spacing: 0px;
    padding-left: 2px; }
  .util-search.util-search-cn button::before {
    content: '搜索'; } }

#nav {
  max-width: 957px;
  margin-left: auto;
  margin-right: auto;
  clear: both; }

.nav-global {
  display: -webkit-flex;
  /* Safari */
  display: flex;
  justify-content: flex-end; }

.nav-global > li {
  width: 16.5%;
  text-align: center;
  border-left: 1px solid #B5B5B5;
  position: relative; }

.nav-global > li:nth-child(8) {
  border-right: 1px solid #B5B5B5; }

.nav-global > li:nth-child(n+8) {
  display: none; }

.nav-global > li:last-child {
  display: block;
  border-right: 1px solid #B5B5B5; }

.nav-global a {
  line-height: 1.1;
  display: block;
  padding: 5px 3px;
  letter-spacing: 1px; }

.nav-global .nav-global-sub {
  display: none; }

@media screen and (min-width: 768px) {
  .nav-global > li > a:hover, .nav-global > li.current > a, .nav-global > li.open > a {
    color: #00a088; }
  .nav-global > li > a:hover::after,
  .nav-global > li.current > a::after,
  .nav-global > li.open > a::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 20%;
    right: 20%;
    height: 3px;
    background-color: #00a088; } }

@media screen and (max-width: 767px) {
  .nav-global {
    display: block; }
  .nav-global > li {
    width: 100%;
    text-align: left;
    border-left: none; }
  .nav-global > li:nth-child(6) {
    border-right: none; }
  .nav-global > li:nth-child(n+7) {
    display: block; }
  .nav-global .nav-global-list {
    border-bottom: 1px solid #727171;
    letter-spacing: 0px; }
  .nav-global a {
    color: #ffffff;
    text-decoration: none;
    position: relative;
    display: block;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 10px;
    padding-right: 40px;
    background-color: #595757; }
  .nav-global > li > a {
    font-weight: bold; }
  .nav-global .nav-global-sub li a {
    padding-left: 20px;
    letter-spacing: 0px; }
  .nav-global a::after {
    position: absolute;
    display: block;
    content: '';
    width: 20px;
    height: 20px;
    top: 50%;
    right: 10px;
    margin-top: -10px;
    overflow: hidden;
    background-image: url(../../common/img/icon_mob_menu.png);
    background-size: 20px 60px;
    background-position: 0 -20px; }
  .nav-global > li:not(.nav-global-list) > a::after, .nav-global .nav-global-sub li a::after {
    background-position: 0 0; }
  .nav-global .nav-global-list.open > a::after {
    background-position: 0 -40px; } }

/* --------------------------------
 * footer
 * -------------------------------- */
.footer {
  background-color: #00a088; }

.footer-nav-wrap {
  display: -webkit-flex;
  /* Safari */
  display: flex;
  padding-top: 30px;
  padding-bottom: 10px;
  color: #fff; }

.footer-nav {
  width: 25%;
  padding-right: 10px; }

.footer-nav:last-of-type {
  padding-right: 0px; }

.footer-nav ul {
  margin-bottom: 25px; }

.footer-nav ul li {
  font-size: 1.3rem;
  font-weight: normal;
  letter-spacing: 0px; }

.footer-nav > ul > li {
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 3px; }

.footer-nav ul li a {
  color: #fff;
  text-decoration: none;
  padding: 3px 0 5px;
  display: block; }

.footer-nav ul li a:hover {
  text-decoration: underline; }

.footer-nav-util-wrap {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0px 15px 30px 0px; }

.footer-nav-util-wrap::after {
  content: "";
  display: block;
  clear: both; }

.footer-nav-util {
  display: -webkit-flex;
  /* Safari */
  display: flex;
  /* float: left; */
  -webkit-flex-wrap: wrap;
  /* Safari */
  flex-wrap: wrap; }

.footer-nav-util li {
  font-size: 1.2rem;
  line-height: 1;
  border-right: 1px solid #fff; }

.footer-nav-util li:last-child {
  border-right: none; }

.footer-nav-util li a {
  display: block;
  padding: 3px 15px;
  color: #fff;
  text-decoration: none;
  text-align: center;
  letter-spacing: 1px; }

.footer-nav-util li a:hover {
  text-decoration: underline; }

.copyright {
  font-size: 1.2rem;
  line-height: 1;
  text-align: right;
  padding: 3px 0px;
  color: #fff; }

@media only screen and (max-width: 767px) {
  .footer-nav-wrap {
    display: none; }
  .footer-nav-util-wrap {
    padding: 0px 0px 15px; }
  .footer-nav-util {
    float: none;
    margin-bottom: 15px; }
  .footer-nav-util li {
    width: 50%;
    font-size: 1.4rem;
    border-right: 1px solid #fff; }
  .footer-nav-util li:nth-child(even) {
    border-right: none; }
  .footer-nav-util li:last-child {
    /*
	Mobile layout nav odd items  -> 100%
	Even items -> 50%
	 */
    width: 100%; }
  .footer-nav-util li a {
    padding: 15px 5px;
    border-bottom: 1px solid #fff; }
  .copyright {
    text-align: center;
    padding: 0px 5px; }
  :lang(zh) .footer-nav-util li:last-child {
    width: 50%; }
  :lang(en) .footer-nav-util li:last-child {
    width: 50%; } }

/* --------------------------------
 * EN-CN
 * -------------------------------- */
header.header-en {
  height: 120px; }

@media screen and (max-width: 767px) {
  header.header-en {
    height: 60px; } }

:lang(en) .util-inquiry {
  display: none; }

:lang(zh) .util-inquiry {
  display: none; }

.nav-global.nav-global-en {
  display: table; }

.nav-global.nav-global-en > li {
  width: 16%;
  display: table-cell;
  vertical-align: middle;
  text-align: center; }

.nav-global.nav-global-cn > li {
  width: 16%;
  display: table-cell;
  vertical-align: middle;
  text-align: center; }

.nav-global.nav-global-en > li:nth-child(6), .nav-global.nav-global-cn > li:nth-child(6) {
  border-right: none; }

.nav-global.nav-global-en > li:nth-child(5), .nav-global.nav-global-cn > li:nth-child(5) {
  border-right: 1px solid #B5B5B5; }

.nav-global.nav-global-en > li:nth-child(n+6), .nav-global.nav-global-cn > li:nth-child(n+6) {
  display: none; }

.nav-global.nav-global-en > li:nth-child(5) {
  border-right: none; }

.nav-global.nav-global-en > li:last-child {
  display: table-cell; }

.nav-global.nav-global-cn > li:last-child {
  display: table-cell; }

.nav-global.nav-global-en a {
  line-height: 1.2;
  letter-spacing: 1px; }

@media screen and (max-width: 767px) {
  .nav-global.nav-global-en {
    display: block; }
  .nav-global.nav-global-en > li, .nav-global.nav-global-cn > li {
    width: 100%;
    display: block;
    vertical-align: middle;
    text-align: left; }
  .nav-global.nav-global-en > li:nth-child(5), .nav-global.nav-global-cn > li:nth-child(5) {
    border-right: none; }
  .nav-global.nav-global-en > li:nth-child(n+6), .nav-global.nav-global-cn > li:nth-child(n+6) {
    display: block; } }

.footer-nav.footer-nav-en > ul > li {
  letter-spacing: 2px; }

/* --------------------------------
 * content
 * -------------------------------- */
@media screen and (max-width: 767px) {
  .wrapper {
    padding-top: 60px;
    /* header固定60 */ } }

/* Top ------------------------ */
.top-btn-wrap {
  display: -webkit-flex;
  /* Safari */
  display: flex;
  -webkit-flex-wrap: wrap;
  /* Safari */
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  /* Safari */
  justify-content: space-between; }

.top-btn-wrap a {
  display: block;
  margin-bottom: 30px;
  text-decoration: none; }

.top-btn-wrap a img {
  width: 100%;
  height: auto;
  margin-bottom: 5px; }

.top-btn-wrap h3 {
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.3;
  padding-left: 15px;
  position: relative;
  margin-bottom: 5px; }

.top-btn-wrap h3::before {
  font-family: 'fontello';
  content: '\f105';
  position: absolute;
  left: 3px; }

.top-btn-wrap01 a {
  width: 49.2%;
  margin-right: auto;
  margin-left: auto; }

.top-btn-wrap02 a {
  width: 49.2%; }

.top-btn-wrap03 a,
.top-btn-wrap03 .dummy-btn {
  width: 32.5%; }

.top-btn-wrap04 a {
  width: 24%; }

.top-btn-wrap-noimage a {
  border-radius: 6px; }

.top-btn-wrap-noimage a,
.top-btn-wrap-noimage .dummy-btn {
  background-color: #efeee8;
  padding: 20px 25px 17px;
  margin-bottom: 20px; }

.top-btn-wrap-noimage a:hover {
  background-color: #d7f3d0; }

.dummy-btn {
  background: #fff !important; }

@media screen and (max-width: 767px) {
  .top-btn-wrap01 a, .top-btn-wrap02 a, .top-btn-wrap03 a {
    width: 100%; }
  .top-btn-wrap04 a {
    width: 49%; }
  .top-btn-wrap-noimage a {
    background-color: #efeee8;
    padding: 15px 20px 12px;
    margin-bottom: 10px; } }

.top-btn-wrap.top-btn-wrap-left {
  -webkit-justify-content: flex-start;
  /* Safari */
  justify-content: flex-start; }

.top-btn-wrap.top-btn-wrap-left.top-btn-wrap04 a {
  margin-right: 1.33%; }

.top-btn-wrap.top-btn-wrap-left.top-btn-wrap04 a:nth-child(4n) {
  margin-right: 0%; }

@media screen and (max-width: 767px) {
  .top-btn-wrap.top-btn-wrap-left.top-btn-wrap04 a {
    margin-right: 0%; } }

@media screen and (min-width: 768px) {
  .nav-global li:first-child {
    display: none; }
  .nav-global-en li:first-child {
    display: table-cell; }
  .nav-global-cn li:first-child {
    display: table-cell; }
  .nav-global .open .nav-global-sub--opend {
    display: block;
    background-color: transparent;
    margin-top: calc(0px);
    padding-top: calc(0px);
    z-index: 999;
    position: absolute;
    text-align: left;
    width: 300px;
    border: solid 2px #fff;
    border-top: none; }
    .nav-global .open .nav-global-sub--opend li:first-child {
      display: none; }
    .nav-global .open .nav-global-sub--opend li:nth-child(2) {
      margin-top: 10px; }
    .nav-global .open .nav-global-sub--opend li {
      background-color: #ddd;
      border-top: solid 1px #eee; }
      .nav-global .open .nav-global-sub--opend li a {
        padding: 8px;
        display: block;
        letter-spacing: 0px; }
        .nav-global .open .nav-global-sub--opend li a:hover, .nav-global .open .nav-global-sub--opend li a:active {
          background-color: #00a088;
          color: #fff;
          transition: background-color 0.2s linear; } }

.footer-contact {
  display: none; }
  @media screen and (max-width: 767px) {
    .footer-contact {
      display: block;
      text-align: center;
      font-size: 120%; }
      .footer-contact a {
        background-color: #e4e4e4;
        text-decoration: none;
        display: block;
        padding: 20px; }
        .footer-contact a span {
          background-image: url(/common/img/icon-mail.png);
          background-repeat: no-repeat;
          background-position: left center;
          padding-left: 40px;
          padding-bottom: 10px;
          padding-top: 10px; } }

/* --------------------------------
 * object
 * -------------------------------- */
/* title ------------------------ */
.title-header-top {
  background: #F3F6F9;
  margin-bottom: 20px;
  line-height: 1; }

.title-header-top span {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto; }

.title-header-top img {
  width: 100%;
  height: auto; }

.title-header {
  display: none; }

@media screen and (max-width: 767px) {
  .title-header {
    display: block;
    background: #00a088;
    color: #fff;
    font-size: 2.6rem;
    margin-bottom: 15px;
    line-height: 1;
    padding: 10px 15px;
    letter-spacing: 2px; } }

.title-header-s {
  color: #00a088;
  font-size: 3.0rem;
  margin-bottom: 20px;
  letter-spacing: 1px;
  line-height: 1.3; }

.title-header-ss {
  background-color: #efefef;
  font-size: 2.0rem;
  margin-top: -10px;
  margin-bottom: 10px;
  letter-spacing: 1px;
  line-height: 1.2;
  border-left: 8px solid #00a088;
  padding: 10px 5px 9px 10px; }

.title-header-sss {
  color: #00a088;
  font-size: 1.8rem;
  margin-bottom: 15px;
  padding-bottom: 3px;
  padding-right: 15px;
  line-height: 1.3;
  border-bottom: 1px solid #00a088; }

.title-header-ssss {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 1.4; }

.title-header-s5 {
  font-size: 1.4rem;
  font-weight: normal;
  margin-bottom: 10px;
  line-height: 1.4;
  border-bottom: solid 1px #999; }

.title-header-singlepage {
  background-color: #efefef;
  font-size: 2.0rem;
  margin-bottom: 10px;
  letter-spacing: 1px;
  padding: 10px 5px 9px 10px; }

.title-header-singlepage span {
  display: block;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px; }

@media screen and (max-width: 767px) {
  .title-header-s {
    font-size: 2.5rem;
    /*letter-spacing: 0px;*/ }
  .title-header-ss {
    font-size: 1.7rem; }
  .title-header-sss {
    font-size: 1.7rem; } }

/* cols ------------------------ */
.cols, .cols-none {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.cols-center {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.cols-reverse {
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.col-20p {
  width: 20%; }

.col-30p {
  width: 30%; }

.col-33p {
  width: 33%; }

.col-38p {
  width: 38%; }

.col-40p {
  width: 40%; }

.col-48p {
  width: 48%; }

.col-58p {
  width: 58%; }

.col-68p {
  width: 68%; }

.col-78p {
  width: 78%; }

.col-100p {
  width: 100%; }

.col-none-15p {
  width: 15%; }

.col-none-20p {
  width: 20%; }

.col-none-40p {
  width: 40%; }

.col-none-48p {
  width: 48%; }

.col-none-58p {
  width: 58%; }

.col-none-78p {
  width: 78%; }

.col-none-83p {
  width: 83%; }

@media screen and (max-width: 767px) {
  .cols {
    display: block; }
  .col-20p, .col-30p, .col-33p, .col-38p, .col-40p, .col-48p, .col-58p, .col-68p, .col-78p, .col-100p {
    width: 100%;
    margin-bottom: 15px; } }

/* icon ------------------------ */
.icon-pdf::after, .icon-elsx::after, .icon-movie::after {
  margin-left: 8px;
  margin-right: 8px; }

.icon-pdf::after {
  content: url(/common/img/icon_pdf_small.png); }

.icon-elsx::after {
  content: url(/common/img/icon_elsx.png); }

.icon-movie::after {
  content: url(/common/img/icon_movie.gif); }

/*a[href^="//www"]::after {
	font-family: 'fontello';
	content: '\e802';
	margin-left: 5px;
}*/
/* adobe-reader ------------------------ */
.adobe-reader-wrap {
  margin-bottom: 30px; }

.adobe-reader-description {
  margin-bottom: 10px; }

.adobe-reader-banner {
  vertical-align: middle;
  margin-right: 10px; }

@media screen and (max-width: 767px) {
  .adobe-reader-banner {
    float: left; } }

/* .pager ------------------------ */
.pager {
  overflow: hidden;
  text-align: right;
  margin-bottom: 20px;
  font-size: 0; }

.pager ul li {
  margin-right: 5px;
  display: inline-block; }

.pager ul li span, .pager ul li a {
  display: block;
  padding: 0.4em 1em;
  border-radius: 2px;
  font-size: 1.2rem; }

.pager ul li a {
  background: #ccc;
  color: #000;
  text-decoration: none; }

.pager ul li a:hover {
  background: #727171;
  color: #FFF; }

.pager02 {
  overflow: hidden;
  text-align: right;
  margin-bottom: 20px;
  font-size: 0; }

.pager02 ul li {
  margin-right: 5px;
  display: inline-block; }

.pager02 ul li a {
  display: block;
  padding: 0.4em 1em;
  border-radius: 2px;
  font-size: 1.2rem;
  text-decoration: none;
  background: #fff;
  color: #000; }

.pager02 ul li a:hover, .pager02 ul li.current a {
  background-color: #00a088;
  color: #fff; }

/* inner ------------------------ */
.inner {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px; }

.inner:after {
  content: "";
  display: block;
  clear: both; }

/* clearfix ------------------------ */
.clearfix::after {
  content: "";
  display: block;
  clear: both; }

/* for-pc, for-mobile ------------------------ */
.for-pc {
  display: block; }

.for-mobile {
  display: none; }

@media screen and (max-width: 767px) {
  .for-pc {
    display: none; }
  .for-mobile {
    display: block; } }

/* image ------------------------ */
.flex-img img {
  width: 100%;
  height: auto; }

.flex-img-for-pc img {
  width: 100%;
  height: auto; }

.flex-img-for-mobile img {
  width: auto;
  height: auto; }

@media screen and (max-width: 767px) {
  .flex-img-for-pc img {
    width: auto;
    height: auto; }
  .flex-img-for-mobile img {
    width: 100%;
    height: auto; } }

.center-img, .center-img-large {
  text-align: center; }

/*.center-img figure, .center-img-large figure {
	display: table;
	width: 120px;
	margin-left: auto;
	margin-right: auto;
}
@media screen and (max-width: 767px) {
.center-img figure, .center-img-large figure {
	width: 100%;
}
}*/
@media screen and (max-width: 767px) {
  .center-img img {
    width: 100%;
    height: auto; } }

@media screen and (max-width: 900px) {
  .center-img-large img {
    width: 100%;
    height: auto; } }

.center-img-max-with {
  margin-right: auto;
  margin-left: auto; }

@media screen and (max-width: 900px) {
  .center-img-max-with img {
    width: 100%;
    height: auto; } }

/* ul,ol ------------------------ */
.ul-restore {
  padding-left: 25px;
  list-style-type: disc; }

.ol-restore {
  padding-left: 25px;
  list-style-type: decimal; }

/* --------------------------------
 * utility
 * -------------------------------- */
/* color ------------------------ */
.u-color-keygreen {
  color: #00a088; }

.u-color-red {
  color: #ff0000; }

/* weight ------------------------ */
.u-weight-bold {
  font-weight: bold; }

/* text-align ------------------------ */
.u-align-left {
  text-align: left; }

.u-align-center {
  text-align: center; }

.u-align-right {
  text-align: right; }

/* size ------------------------ */
.u-fsize-10 {
  font-size: 1.0rem; }

.u-fsize-12 {
  font-size: 1.2rem; }

.u-fsize-13 {
  font-size: 1.3rem; }

.u-fsize-16 {
  font-size: 1.6rem; }

.u-fsize-18 {
  font-size: 1.8rem; }

.u-fsize-22 {
  font-size: 2.2rem; }

/* line-height ------------------------ */
.u-lh-1 {
  line-height: 1; }
  .u-lh-1_wrap p {
    line-height: 1; }

.u-lh-1_5 {
  line-height: 1.5; }
  .u-lh-1_5_wrap p {
    line-height: 1.5; }

.u-lh-1_8 {
  line-height: 1.8; }
  .u-lh-1_8_wrap p {
    line-height: 1.8; }

.u-lh-2 {
  line-height: 2; }
  .u-lh-2_wrap p {
    line-height: 2; }

/* margin-bottom ------------------------ */
.u-mb-05 {
  margin-bottom: 5px; }
  .u-mb-05_wrap p {
    margin-bottom: 5px; }

.u-mb-10 {
  margin-bottom: 10px; }
  .u-mb-10_wrap p {
    margin-bottom: 10px; }

.u-mb-15 {
  margin-bottom: 15px; }
  .u-mb-15_wrap p {
    margin-bottom: 15px; }

.u-mb-20 {
  margin-bottom: 20px; }
  .u-mb-20_wrap p {
    margin-bottom: 20px; }

.u-mb-25 {
  margin-bottom: 25px; }
  .u-mb-25_wrap p {
    margin-bottom: 25px; }

.u-mb-30 {
  margin-bottom: 30px; }
  .u-mb-30_wrap p {
    margin-bottom: 30px; }

.u-mb-35 {
  margin-bottom: 35px; }
  .u-mb-35_wrap p {
    margin-bottom: 35px; }

.u-mb-40 {
  margin-bottom: 40px; }
  .u-mb-40_wrap p {
    margin-bottom: 50px; }

.u-mb-50 {
  margin-bottom: 50px; }
  .u-mb-50_wrap p {
    margin-bottom: 50px; }

.u-mb-60 {
  margin-bottom: 60px; }
  .u-mb-60_wrap p {
    margin-bottom: 60px; }

.u-mb-lh1 {
  margin-bottom: 1em; }
  .u-mb-lh1_wrap p {
    margin-bottom: 1em; }

.u-mb-lh2 {
  margin-bottom: 2em; }
  .u-mb-lh2_wrap p {
    margin-bottom: 2em; }

/* margin-top ------------------------ */
.u-mt-05 {
  margin-top: 5px; }
  .u-mt-05_wrap p {
    margin-top: 5px; }

.u-mt-10 {
  margin-top: 10px; }
  .u-mt-10_wrap p {
    margin-top: 10px; }

.u-mt-15 {
  margin-top: 15px; }
  .u-mt-15_wrap p {
    margin-top: 15px; }

.u-mt-20 {
  margin-top: 20px; }
  .u-mt-20_wrap p {
    margin-top: 20px; }

.u-mt-25 {
  margin-top: 25px; }
  .u-mt-25_wrap p {
    margin-top: 25px; }

.u-mt-30 {
  margin-top: 30px; }
  .u-mt-30_wrap p {
    margin-top: 30px; }

.u-mt-35 {
  margin-top: 35px; }
  .u-mt-35_wrap p {
    margin-top: 35px; }

.u-mt-40 {
  margin-top: 40px; }
  .u-mt-40_wrap p {
    margin-top: 40px; }

.u-mt-50 {
  margin-top: 50px; }
  .u-mt-50_wrap p {
    margin-top: 50px; }

.u-mt-60 {
  margin-top: 60px; }
  .u-mt-60_wrap p {
    margin-top: 60px; }

/* margin-left ------------------------ */
.u-ml-10 {
  margin-left: 10px; }
  .u-ml-10_wrap p {
    margin-left: 10px; }

.u-ml-20 {
  margin-left: 20px; }
  .u-ml-20_wrap p {
    margin-left: 20px; }

.numeric--section {
  counter-reset: number; }

.numeric--number:before {
  counter-increment: number;
  content: counter(number) ". "; }

/* new_materials ------------------------ */
.hydrogen__table {
  font-size: 1.3rem; }

.hydrogen_list li {
  list-style: disc;
  margin-left: 30px; }

.hydrogen__product {
  background-color: #DAE3F3;
  padding: 20px; }

.hydrogen_title {
  text-align: center;
  color: #fff;
  background-color: #2F5597;
  border-radius: 3px;
  padding: 3px; }

.hydrogen__info {
  display: flex;
  width: 100%;
  flex-wrap: nowrap; }

@media screen and (max-width: 768px) {
  .hydrogen__info {
    display: block !important; } }

.hydrogen__info--p {
  text-decoration: none;
  color: #2F5597;
  font-weight: bold;
  padding-left: 30px; }

@media screen and (max-width: 768px) {
  .hydrogen__info--p {
    padding-left: 0px; } }

.hydrogen__bold {
  font-weight: bold; }

.embed--youtube--video iframe {
  max-width: 100% !important; }

.page-covid19 .title-header-singlepage {
  background-color: #00a088;
  color: #fff; }

.covid19-topics {
  border: none; }
  .covid19-topics article {
    border-bottom: solid 1px #ccc;
    padding: 1.5rem 3rem; }
    .covid19-topics article a {
      display: flex; }
      .covid19-topics article a time {
        margin-right: 2rem; }
      .covid19-topics article a:link, .covid19-topics article a:visited {
        text-decoration: none; }

.emergency-news {
  width: 100%;
  margin-bottom: 3rem;
  background: #fff;
  border: solid 1px rgba(0, 160, 136, 0.2);
  font-size: 1.3em; }
  .emergency-news .inner {
    padding: 1.5rem 3rem; }
  .emergency-news a {
    display: block;
    position: relative;
    padding-left: 15px;
    vertical-align: middle; }
    .emergency-news a:before {
      font-family: 'fontello';
      content: '\f105  ';
      font-size: 2rem;
      text-decoration: none; }
    .emergency-news a:link, .emergency-news a:visited {
      text-decoration: none; }
    .emergency-news a:active, .emergency-news a:hover {
      text-decoration: underline; }

.list-mark--disk {
  padding-left: 2em; }
  .list-mark--disk .item {
    list-style-type: disc; }

.emergency-announce {
  max-width: 1000px;
  margin: 2rem auto;
  color: #fff;
  background-color: #A00018;
  font-size: 1.3em; }
  .emergency-announce .inner {
    padding: 1.5rem 3rem; }
  .emergency-announce a {
    display: block;
    position: relative;
    vertical-align: middle;
    text-align: center;
    color: #fff; }

.emergency-announce a:link, .emergency-announce a:visited {
  text-decoration: none; }

.emergency-announce a:before {
  font-family: 'fontello';
  content: '\f105 ';
  font-size: 2rem;
  text-decoration: none;
  padding-right: 0.5rem; }

.emergency-announce_underbar {
  text-decoration: underline;
  text-decoration-color: #fff; }

@font-face {
  font-family: 'fontello';
  src: url("/common/font/fontello.eot?76404765");
  src: url("/common/font/fontello.eot?76404765#iefix") format("embedded-opentype"), url("/common/font/fontello.woff2?76404765") format("woff2"), url("/common/font/fontello.woff?76404765") format("woff"), url("/common/font/fontello.ttf?76404765") format("truetype"), url("/common/font/fontello.svg?76404765#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'fontello';
      src: url('/common/font/fontello.svg?76404765#fontello') format('svg');
    }
  }
  */
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-mail:before {
  content: '\e800'; }

/* '' */
.icon-search:before {
  content: '\e801'; }

/* '' */
.icon-blink:before {
  content: '\e802'; }

/* '' */
.icon-angle-left:before {
  content: '\f104'; }

/* '' */
.icon-angle-right:before {
  content: '\f105'; }

/* '' */
.icon-angle-up:before {
  content: '\f106'; }

/* '' */
.icon-angle-down:before {
  content: '\f107'; }

/* '' */
.underconstruction--wrapper {
  display: flex;
  height: 40vh;
  align-items: center; }

.underconstruction--inner {
  width: 100%; }

.underconstruction--message {
  text-align: center;
  font-size: 1.6em;
  color: #006d5d; }

._p-cookiepolicy h3 {
  font-size: 1.6em;
  padding-bottom: 0.5em;
  margin-bottom: 1em;
  border-bottom: solid 1px #00a088; }

._p-cookiepolicy p {
  margin-bottom: 1em; }

._p-cookiepolicy ul {
  margin-bottom: 1em;
  padding-left: 3em; }
  ._p-cookiepolicy ul .item {
    list-style: disc; }

.nav-sidemenu li .diabled--anker {
  padding: 20px 0px 18px 18px;
  position: relative;
  display: block;
  text-decoration: none;
  color: #999; }

.page-error .breadcrumb-list,
.page-error .title-header-singlepage {
  display: none; }

.page-error .helo {
  position: relative;
  min-height: 400px;
  text-align: center;
  font-size: 48px;
  padding-top: 100px; }
  @media screen and (max-width: 767px) {
    .page-error .helo {
      padding-top: 50px;
      min-height: 290px;
      font-size: 24px; } }
  .page-error .helo h1, .page-error .helo p {
    position: relative; }
  .page-error .helo .error-message {
    font-size: 16px;
    margin-bottom: 100px; }
  .page-error .helo:before {
    content: "";
    display: block;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(/img/mainvidual_desktop.jpg) center top no-repeat;
    background-position-y: -60px;
    background-size: cover;
    opacity: .2; }
  .page-error .helo .inner {
    max-width: none;
    padding: 0; }
    .page-error .helo .inner::before {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      background-image: linear-gradient(rgba(255, 255, 255, 0), white);
      height: 200px;
      width: 100%; }

.page-error .error-top {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  margin-bottom: 150px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; }
  @media screen and (max-width: 767px) {
    .page-error .error-top {
      margin-top: 30px;
      margin-bottom: 70px; } }
  .page-error .error-top .item {
    padding: 2rem 0; }
    @media screen and (max-width: 767px) {
      .page-error .error-top .item {
        padding: 1rem 0;
        margin-bottom: 20px; }
        .page-error .error-top .item:nth-last-child {
          margin-bottom: 0; } }
    .page-error .error-top .item a {
      width: 190px;
      margin: 0 20px;
      text-align: center;
      display: block;
      border: solid 1px #00a088;
      border-radius: 3px;
      text-decoration: none;
      padding: 5px 8px;
      color: #fff;
      background-color: #00a088;
      display: inline-block;
      color: #efefef;
      text-decoration: none; }
      .page-error .error-top .item a:before {
        font-family: 'fontello';
        content: '\f105';
        margin-right: 5px; }
      .page-error .error-top .item a:hover, .page-error .error-top .item a:active {
        background-color: #fff;
        color: #00a088;
        transition: all 200ms 0ms ease-out; }
      .page-error .error-top .item a:active, .page-error .error-top .item a:hover {
        color: #00a088; }

.title-environment {
  color: #ffffff;
  font-size: 2.0rem;
  margin-bottom: 20px;
  letter-spacing: 2px;
  line-height: 1;
  padding: 8px 20px; }

.title-environment-environment {
  background: #ABCD03; }

.title-environment-society {
  background: #5EB7E8; }

.title-header {
  display: none; }

.web-column-list li {
  position: relative;
  padding-bottom: 1em; }

.web-column-list a:hover {
  background-color: rgba(244, 246, 236, 0.66); }

.web-column-list--label {
  font-size: 2rem;
  display: block;
  color: #333;
  margin-bottom: 0.5em; }

.web-column-list--author {
  color: #333;
  display: block; }

.web-column-list--number {
  position: absolute;
  bottom: 0;
  right: 0;
  display: block;
  font-size: 1.4rem;
  color: #999;
  margin-bottom: 8px; }
  .web-column-list--number .season {
    width: 12rem;
    display: inline-block;
    text-align: right; }
  @media screen and (max-width: 767px) {
    .web-column-list--number {
      position: relative; }
      .web-column-list--number .season {
        display: inline;
        text-align: left; } }

.web-column-list--notice {
  color: #999;
  text-align: right; }

.web-column-list-number2 {
  display: block;
  font-size: 1.4rem;
  color: #999;
  margin-bottom: 8px; }

@media screen and (min-width: 768px) {
  .hq-mainimg {
    margin-bottom: 0.5em; } }

.hq-mainimg img {
  margin: 0 calc(50% - 50vw);
  width: 100vw; }
  @media screen and (min-width: 768px) {
    .hq-mainimg img {
      margin: 0 0;
      width: 100%; } }

@media screen and (min-width: 768px) {
  .hq-mainimg-wide {
    width: 100vw;
    margin: 0 0 0 calc(50% - 50vw); }
    .hq-mainimg-wide img {
      width: 100%; } }

.hq-head {
  font-family: "メイリオ", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif; }
  @media screen and (min-width: 768px) {
    .hq-head {
      margin-top: 0em; } }
  .hq-head .corner-label {
    padding-bottom: 40px;
    text-align: left;
    text-indent: -9999px;
    margin-bottom: 3em; }
  .hq-head .title {
    font-size: 7vw;
    line-height: 7.7vw;
    margin-bottom: 3.5vw;
    margin-top: 3.5vw; }
    @media screen and (min-width: 768px) {
      .hq-head .title {
        font-size: 1.7em;
        line-height: 120%;
        margin-top: 0;
        margin-bottom: .5em; } }
  .hq-head .name-en {
    text-align: right;
    font-size: 80%;
    line-height: 100%; }
  .hq-head .belong {
    text-align: right; }
  .hq-head .name {
    text-align: right;
    font-size: 9.8vw;
    line-height: 120%;
    margin: 0 0 0 0;
    padding: 0; }
    .hq-head .name .belong-in-name {
      display: block; }
      @media screen and (min-width: 768px) {
        .hq-head .name .belong-in-name {
          display: inline; } }
    @media screen and (min-width: 768px) {
      .hq-head .name {
        font-size: 2.8em; } }
  .hq-head .lead {
    font-family: sans-serif;
    font-size: 5.6vw;
    margin-top: 3.5vw;
    margin-bottom: 1em; }
    @media screen and (min-width: 768px) {
      .hq-head .lead {
        font-size: 1.2em;
        margin-top: 0; } }
  .hq-head:after {
    content: "";
    display: block;
    width: 20%;
    margin: 3em auto;
    border-top: solid 3px #ccc; }
  .hq-head--compass .corner-label {
    background: url(/hq/compass/img/hq-compass-headback.jpg) right top no-repeat;
    background-size: contain; }
  .hq-head--legendary .corner-label {
    background: url(/hq/legendary_technology/img/legendary_logo.png) right top no-repeat;
    background-size: contain; }
  .hq-head--ohi .corner-label {
    background: url(/hq/one_hour_interview/img/ohi_logo.png) right top no-repeat;
    background-size: contain; }

.hq-pages-head {
  text-align: right;
  padding: 0 0 20px 0; }
  .hq-pages-head .title, .hq-pages-head .name {
    font-size: 5vw;
    margin: 0.2em 0; }
    @media screen and (min-width: 768px) {
      .hq-pages-head .title, .hq-pages-head .name {
        font-size: 1.5em; } }

.hq-body,
.hq-profile {
  margin-top: 20px;
  font-family: "メイリオ", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif; }

.hq-body h4 {
  font-weight: bold;
  font-size: 6vw;
  margin-bottom: 3vw;
  margin-top: 3vw; }
  @media screen and (min-width: 768px) {
    .hq-body h4 {
      font-size: 1.4em;
      margin-top: 0.7em;
      margin-bottom: 0.7em; } }

.hq-body p {
  font-size: 4.5vw;
  font-family: "メイリオ", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  line-height: 1.7em;
  margin-bottom: 4.5vw;
  text-align: justify; }
  @media screen and (min-width: 768px) {
    .hq-body p {
      font-size: 1.2em;
      margin-top: 0.7em;
      margin-bottom: 0.7em; } }

.insertimg {
  width: 80%;
  margin: 2em  auto;
  background: #efefef;
  padding: 1em;
  border-radius: 3px;
  overflow: hidden; }
  .insertimg img {
    width: 100%; }
  .insertimg .caption {
    margin: 1em 2em;
    font-size: 3.5vw;
    font-family: sans-serif;
    line-height: 1.2em;
    text-align: left; }
  @media screen and (min-width: 768px) {
    .insertimg {
      width: 40%;
      margin: 0 0 0 2em;
      overflow: hidden;
      float: right; }
      .insertimg .caption {
        font-size: 1.0em;
        margin: 1em 0; }
      .insertimg img {
        width: 100%; } }
  .insertimg.insertimg-center {
    padding: 0;
    background: none;
    width: 100vw;
    margin: 0 calc(50% - 50vw); }
    @media screen and (min-width: 768px) {
      .insertimg.insertimg-center {
        padding: 1em;
        background: #efefef;
        width: 70%;
        margin: 0 auto;
        float: none; } }

.hq-profile {
  overflow: hidden; }
  .hq-profile:before {
    content: "";
    display: block;
    width: 20%;
    margin: 3em auto;
    border-top: solid 3px #ccc; }
  .hq-profile .awrds {
    border-top: solid 1px #999;
    display: inline-block;
    margin-top: 0.5em;
    padding-top: 0.5em; }

.hq-profile p {
  font-size: 3.5vw;
  text-align: justify; }
  .hq-profile p.profile-lead {
    font-weight: bold;
    font-size: 5vw;
    font-feature-settings: "palt";
    padding: 0 0 0.5em 0; }
  @media screen and (min-width: 768px) {
    .hq-profile p {
      font-size: 1.0em; }
      .hq-profile p.profile-lead {
        font-size: 1.6em; } }

.profileimg {
  width: 50%;
  float: left;
  margin-right: 3.5vw; }
  .profileimg img {
    width: 100%; }

.profileimg.layout-horizon {
  width: 100%;
  float: none; }

.hq-pager {
  border-top: solid 1px #ccc;
  clear: both;
  margin: 30px 0;
  padding: 15px 0; }
  .hq-pager .page-next-topic {
    color: #999;
    text-align: center;
    font-size: 1.3em;
    margin: 20px 0; }
    .hq-pager .page-next-topic a {
      display: inline-block;
      text-decoration: none;
      border-bottom: solid 2px #00a088;
      padding: 0.5em 0.3em; }
      @media screen and (min-width: 768px) {
        .hq-pager .page-next-topic a {
          display: inline; } }
  .hq-pager .pager-numbers {
    margin: 2em 0;
    display: flex;
    justify-content: space-around; }
    .hq-pager .pager-numbers a {
      padding: 5px 0 2px 0;
      margin: 0 5px;
      font-size: 100%;
      font-weight: bold;
      border-bottom: solid 2px #00a088;
      text-align: center;
      display: block;
      min-width: 30px;
      min-height: 25px;
      text-decoration: none; }
      .hq-pager .pager-numbers a.current {
        color: white;
        border-radius: 3px;
        background-color: #00a088; }
        .hq-pager .pager-numbers a.current:active, .hq-pager .pager-numbers a.current:hover {
          background-color: #008772; }
      .hq-pager .pager-numbers a:active, .hq-pager .pager-numbers a:hover {
        color: white;
        border-radius: 3px;
        background-color: #00a088; }
    @media screen and (min-width: 768px) {
      .hq-pager .pager-numbers {
        justify-content: center; } }

.hq-body p.interviewer {
  color: #666;
  font-family: sans-serif;
  font-weight: bold;
  padding: 0 0 0 0;
  margin-top: 2.5em;
  margin-bottom: 0.5em; }
  .hq-body p.interviewer:before {
    content: "−−−−";
    letter-spacing: -0.2em;
    margin-right: 0.5em;
    font-weight: normal; }

.hq-body .interviewee--mark {
  padding: 0.25rem 0.5rem;
  background-color: rgba(0, 160, 136, 0.2);
  border: solid 1px #00a088;
  font-family: sans-serif; }

.person--dobble {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap; }
  .person--dobble .person {
    position: relative;
    overflow: hidden;
    width: 100%;
    border-top: solid 1px #00a088;
    border-left: solid 1px #00a088;
    margin-left: 0;
    padding: 0.5rem 3rem; }
    @media screen and (min-width: 768px) {
      .person--dobble .person {
        width: auto;
        margin-left: 1rem; } }
    .person--dobble .person::before {
      display: block;
      z-index: -10;
      position: absolute;
      top: -15px;
      left: -15px;
      content: " ";
      width: 30px;
      height: 30px;
      background-color: #00a088;
      transform: rotate(45deg); }

.pdf-download {
  text-align: center;
  padding: 2em 0 0 0; }
  .pdf-download .pdf-btn a {
    border-radius: 6px;
    color: #fff;
    font-weight: bold;
    display: inline-block;
    text-decoration: none;
    padding: 1em 2em;
    background: linear-gradient(#00a088, #007c6a); }
    .pdf-download .pdf-btn a:active, .pdf-download .pdf-btn a:hover {
      background: linear-gradient(#007c6a, #00a088); }
  .pdf-download aside {
    padding: 2em 0; }
  .pdf-download .adobe-reader-wrap {
    text-align: left; }

.hq-article-info {
  border-top: solid 1px #ccc;
  padding-top: 1em;
  text-align: center; }
  .hq-article-info a {
    text-decoration: none; }
    .hq-article-info a .web-column-list--number {
      position: relative; }
      .hq-article-info a .web-column-list--number .label_author {
        display: block; }

@charset "UTF-8";
/* --------------------------------
 * base
 * -------------------------------- */
html {
	font-size: 62.5%;
}
body {
	background-color: #ffffff;
	color: #333;
	/*font: 1.4rem/1.5 '游ゴシック体','Yu Gothic',YuGothic,'ヒラギノ角ゴシック Pro','Hiragino Kaku Gothic Pro',メイリオ,Osaka,'ＭＳ Ｐゴシック','MS PGothic',Arial,Helvetica,sans-serif;*/
	/*font-family: '游ゴシック体', 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', Arial, Helvetica, sans-serif;*/ /*for IE9*/
	font-family: 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', Arial, Helvetica, sans-serif;
	font-size: 1.4rem; /*for IE9*/
	line-height: 1.5; /*for IE9*/
	-webkit-text-size-adjust: none;
}
// 中国語フォントを指定する場合
// :lang(zh){
// 	body {
// 		font-family: 'Microsoft YaHei',微软雅黑,'Segoe UI',SegoeUI, sans-serif;
// 	}
// }

*, *::before, *::after {
	box-sizing: border-box;
}
a {
	color: #333;
}
a:hover {
	color: #00a088;
}
a[href^="http"] {
	word-wrap:break-word;
}
ruby rp, ruby rt {
	display: inline;
	font-size: 1.4rem;
}
/* page-top ------------------------ */
#page-top {
	width: 58px;
	height: 58px;
	right: 30px;
	bottom: 20px;
	color: #fff;
	font-weight: bold;
	text-align: center;
	text-decoration: none;
	font-family: 'fontello';
	font-size: 4.0rem;
	/*line-height: 54px;*/
	line-height: 50px;
	display: none;
	background-color: rgba(150,150,150,0.85);
	border-radius: 50%;
	position: fixed;
	z-index: 99999;
	border: 2px solid #FFFFFF;
}
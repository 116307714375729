/* cols ------------------------ */

.cols, .cols-none {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
}
.cols-center {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
}
.cols-reverse {
	-webkit-flex-direction: row-reverse;
	flex-direction: row-reverse;
}
.col-20p {
	width: 20%;
}
.col-30p {
	width: 30%;
}
.col-33p {
	width: 33%;
}
.col-38p {
	width: 38%;
}
.col-40p {
	width: 40%;
}
.col-48p {
	width: 48%;
}
.col-58p {
	width: 58%;
}
.col-68p {
	width: 68%;
}
.col-78p {
	width: 78%;
}

.col-100p {
	width: 100%;
}
.col-none-15p {
	width: 15%;
}
.col-none-20p {
	width: 20%;
}
.col-none-40p {
	width: 40%;
}
.col-none-48p {
	width: 48%;
}
.col-none-58p {
	width: 58%;
}
.col-none-78p {
	width: 78%;
}
.col-none-83p {
	width: 83%;
}
@media screen and (max-width: 767px) {
.cols {
	display: block;
}
.col-20p, .col-30p, .col-33p, .col-38p, .col-40p, .col-48p, .col-58p, .col-68p, .col-78p, .col-100p {
	width: 100%;
	margin-bottom: 15px;
}
}
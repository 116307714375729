/* margin-bottom ------------------------ */
.u-mb-05 {
	margin-bottom: 5px;
	&_wrap p {
		// in wrap P element
		margin-bottom: 5px;
	}
}
.u-mb-10 {
	margin-bottom: 10px;
	&_wrap p {
		// in wrap P element
		margin-bottom: 10px;
	}
}
.u-mb-15 {
	margin-bottom: 15px;
	&_wrap p {
		// in wrap P element
		margin-bottom: 15px;
	}
}
.u-mb-20 {
	margin-bottom: 20px;
	&_wrap p {
		// in wrap P element
		margin-bottom: 20px;
	}
}
.u-mb-25 {
	margin-bottom: 25px;
	&_wrap p {
		// in wrap P element
		margin-bottom: 25px;
	}
}
.u-mb-30 {
	margin-bottom: 30px;
	&_wrap p {
		// in wrap P element
		margin-bottom: 30px;
	}
}
.u-mb-35 {
	margin-bottom: 35px;
	&_wrap p {
		// in wrap P element
		margin-bottom: 35px;
	}
}
.u-mb-40 {
	margin-bottom: 40px;
	&_wrap p {
		// in wrap P element
		margin-bottom: 50px;
	}
}
.u-mb-50 {
	margin-bottom: 50px;
	&_wrap p {
		// in wrap P element
		margin-bottom: 50px;
	}
}
.u-mb-60 {
	margin-bottom: 60px;
	&_wrap p {
		// in wrap P element
		margin-bottom: 60px;
	}
}
.u-mb-lh1 {
	margin-bottom: 1em;
	&_wrap p {
		margin-bottom: 1em;
	}
}
.u-mb-lh2 {
	margin-bottom: 2em;
	&_wrap p {
		margin-bottom: 2em;
	}
}

/* margin-top ------------------------ */
.u-mt-05 {
	margin-top: 5px;
	&_wrap p {
		// in wrap P element
		margin-top: 5px;
	}
}
.u-mt-10 {
	margin-top: 10px;
	&_wrap p {
		// in wrap P element
		margin-top: 10px;
	}
}
.u-mt-15 {
	margin-top: 15px;
	&_wrap p {
		// in wrap P element
		margin-top: 15px;
	}
}
.u-mt-20 {
	margin-top: 20px;
	&_wrap p {
		// in wrap P element
		margin-top: 20px;
	}
}
.u-mt-25 {
	margin-top: 25px;
	&_wrap p {
		// in wrap P element
		margin-top: 25px;
	}
}
.u-mt-30 {
	margin-top: 30px;
	&_wrap p {
		// in wrap P element
		margin-top: 30px;
	}
}
.u-mt-35 {
	margin-top: 35px;
	&_wrap p {
		// in wrap P element
		margin-top: 35px;
	}
}
.u-mt-40 {
	margin-top: 40px;
	&_wrap p {
		// in wrap P element
		margin-top: 40px;
	}
}
.u-mt-50 {
	margin-top: 50px;
	&_wrap p {
		// in wrap P element
		margin-top: 50px;
	}
}
.u-mt-60 {
	margin-top: 60px;
	&_wrap p {
		// in wrap P element
		margin-top: 60px;
	}
}
/* margin-left ------------------------ */
.u-ml-10 {
	margin-left: 10px;
	&_wrap p {
		// in wrap P element
		margin-left: 10px;
	}
}
.u-ml-20 {
	margin-left: 20px;
	&_wrap p {
		// in wrap P element
		margin-left: 20px;
	}
}

.hq-head {
  font-family: $basefont;
  @include mq(){
    margin-top: 0em;
  };
  .corner-label {
    padding-bottom: 40px;
    text-align: left;
    text-indent: -9999px;
    margin-bottom: 3em;

    // border: solid 1px red;
  }
  .title {
    font-size: $fs-title;
    line-height:  $fs-title * 1.1;
    margin-bottom: $fs-title / 2;
    margin-top:  $fs-title / 2;
    @include mq{
      font-size: 1.7em;
      line-height:  120%;
      margin-top: 0;
      margin-bottom: .5em;
    };
  }
  .name-en {
    text-align: right;
    font-size: 80%;
    line-height: 100%;
  }
  .belong {
    text-align: right;

  }
  .name {
    text-align: right;
    font-size: $fs-title * 1.4;
    .belong-in-name {
      display: block;
      @include mq{
        display: inline;
      };
    }
    @include mq{
      font-size: 2.8em;
    };
    line-height: 120%;
    margin:  0 0 0 0;
    padding: 0;
  }
  .lead {
    font-family: sans-serif;
    font-size: $fs-title * 0.8;
    margin-top: $fs-title * 0.5;
    margin-bottom: 1em;
    @include mq{
      font-size: 1.2em;
      margin-top: 0;
    }
  }
  &:after {
    content: "";
    display: block;
    width: 20%;
    margin: 3em auto;
    border-top: solid 3px #ccc;
  }
  &--compass {
    .corner-label {
    background: url(/hq/compass/img/hq-compass-headback.jpg) right top no-repeat;
    background-size: contain;
    &:after {
    }
    }
  }
  &--legendary {
    .corner-label {
    background: url(/hq/legendary_technology/img/legendary_logo.png) right top no-repeat;
    background-size: contain;
  }
}
  &--ohi{
    .corner-label {
    background: url(/hq/one_hour_interview/img/ohi_logo.png) right top no-repeat;
    background-size: contain;
  }
}
}
.hq-pages-head {
  text-align: right;
  padding: 0 0 20px 0;
  .title,.name {
    font-size: 5vw;
    margin: 0.2em 0;
    @include mq {
      font-size: 1.5em;
    };
  }
}

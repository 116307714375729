
.hq-mainimg {
  @include mq{
    // margin-bottom: 3em;
    margin-bottom: 0.5em;
  };
  img {
    // width: 100vw;
    margin: 0 calc(50% - 50vw);
    width: 100vw;
    @include mq{
      margin: 0 0;
      width: 100%;
    };

  }

}

.hq-mainimg-wide {
  @include mq{
    width: 100vw;
    margin: 0 0 0 calc(50% - 50vw) ;
    img {
      width: 100%;
    }

  };
}

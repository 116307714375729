// footer-contact

.footer-contact {
  display: none;
  @include mq{
    display: block;
    text-align: center;
    font-size: 120%;
    a {
      background-color: #e4e4e4;
      text-decoration: none;
      display: block;
      padding: 20px;
      span {
        background-image: url(/common/img/icon-mail.png);
        background-repeat: no-repeat;
        background-position: left center;
        padding-left: 40px;
        padding-bottom: 10px;
        padding-top: 10px;
      }
    }
  };
}

$preload-imgs: url(/products/img/cat_img_rasinstall.jpg)
	url(/products/resins_tall/img/printing_ink/img.jpg)
	url(/products/resins_tall/img/paint/img.jpg)
	url(/products/resins_tall/img/adhesive/img.jpg)
	url(/products/resins_tall/img/synthetic_rubber/img.jpg)
	url(/products/resins_tall/img/functional_coating/img.jpg)
	url(/products/resins_tall/img/other/img.jpg)
  url(/products/img/cat_img_paper.jpg)
	url(/products/paper/img/sizing/img.jpg)
	url(/products/paper/img/paper_strengthening/img.jpg)
	url(/products/paper/img/coating_specialties/img.jpg)
  url(/products/img/cat_img_electronics.jpg)
	url(/products/electronics/img/solder/img.jpg)
	url(/products/electronics/img/conductive/img.jpg)
	url(/products/electronics/img/aluminum_brazing/img.jpg);

$color-harima-green : #00a088;
$color-deactive-bg: #dcdcdc;
$color-deactive-text: #666;
$color-base-text: #333;
$transition-base: all 200ms 0ms ease-out;
$typecolor-mossgreen: #0e4140;
$text-dropshadow: 0 0 5px #333;
$color-text-white: #efefef;

$mobile-gutter: 15px;

@mixin fontello-arrow-right {
}
$grad-sColor: rgba(0,0,0,0.3);
$grad-eColor: rgba(0,0,0,0);

@mixin grad-colors ($sColor:$grad-sColor,$eColor:$grad-eColor,$height: 50%){
  background: linear-gradient(0deg,$sColor,$eColor );
  height: $height;
}
@mixin border-transition-normal( $color: #fff,$size: 2px) {
  border: solid $size $color;
  transition: $transition-base;
  @include mq(){
    border: none;
  };
}
@mixin border-transition-hover( $color: #6fc990,$size: 2px) {
  border: solid $size $color;
  transition: $transition-base;
  @include mq(){
    border: none;
  };
}
@mixin link-text-box {
  text-align: center;
  display: block;
  border: solid 1px $color-harima-green;
  border-radius: 3px;
  text-decoration: none;
  padding: 5px 8px;
  &:before {
    font-family: 'fontello';
    content: '\f105';
    margin-right: 5px;
  }
  &:hover,&:active {
    background-color: $color-harima-green;
    color: #fff;
    transition: $transition-base;
  }

}
@mixin link-text-box-r {
  text-align: center;
  display: block;
  border: solid 1px $color-harima-green;
  border-radius: 3px;
  text-decoration: none;
  padding: 5px 8px;
  color: #fff;
  background-color: $color-harima-green;;
  &:before {
    font-family: 'fontello';
    content: '\f105';
    margin-right: 5px;
  }
  &:hover,&:active {
    background-color: #fff;
    color: $color-harima-green;
    transition: $transition-base;
  }

}
@mixin link-arrow {
	&:before {
		font-family: 'fontello';
		content: '\f105';
		margin-right: 5px;
	}
}
$banner-hover-default-rgba : rgba(0,0,0,0.3);
@mixin banner-hover($offset-x:0, $offset-y:0, $blur:5px, $spread:0, $rgba:$banner-hover-default-rgba) {
	line-height: 0;
		transition: all 0.2s ease-out;
		&:hover {
			box-shadow: $offset-x $offset-y $blur $spread $rgba;
			transition: box-shadow 0.2s ease-in;
		}
}

$breakpoints: (
  'md': 'screen and (max-width: 767px)',
  'lg': 'screen and (max-width: 1000px)',
  'xl': 'screen and (max-width: 1200px)',
) !default;

@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin mq-ie11($selector: selector ) {
	@media all and (-ms-high-contrast: none) {
	  *::-ms-backdrop, $selector {
	    /* IE11 にのみ適用される */
			@content;
	  }
	}
}
